import SocialAndNewsletter from "../../components/SocialAndNewsletter";
import resource from '../../images/conference/resource.svg';
import Newsletter from "../../components/Newsletter";
import ContactUs from "../../components/ContactUs";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';

import image1 from "../../images/conference/photos/1.jpg";
import image2 from "../../images/conference/photos/2.jpg";
import image3 from "../../images/conference/photos/3.jpg";
import image4 from "../../images/conference/photos/4.jpg";
import image5 from "../../images/conference/photos/5.jpg";
import image6 from "../../images/conference/photos/6.jpg";
import image7 from "../../images/conference/photos/7.jpg";
import image8 from "../../images/conference/photos/8.jpg";
import image9 from "../../images/conference/photos/9.jpg";
import image10 from "../../images/conference/photos/10.jpg";

import speaker_1 from "../../images/conference/speakers/speaker_1.svg";
import speaker_2 from "../../images/conference/speakers/speaker_2.svg";
import speaker_3 from "../../images/conference/speakers/speaker_3.svg";
import speaker_4 from "../../images/conference/speakers/speaker_4.svg";
import speaker_5 from "../../images/conference/speakers/speaker_5.svg";
import speaker_6 from "../../images/conference/speakers/speaker_6.svg";
import speaker_7 from "../../images/conference/speakers/speaker_7.svg";
import speaker_8 from "../../images/conference/speakers/speaker_8.svg";

import Carousel from 'react-bootstrap/Carousel';

import './ConferencePage.css';

export default function ConferencePage() {
    return (
        <div className="page-container">
            <Header className="header-conference" />
            <div id="conference-banner">
                <div className="conference-title">
                    <div className="conference-title-title">
                        <h1>
                            The REINFORCING Open and Responsible Research and Innovation Forum
                        </h1>
                    </div>
                    <div className="conference-title-subtitle">
                        <h3>
                            Exploring Global Perspectives and Mainstreaming
                            Responsibility and Openness to Shape the Future of ORRI
                        </h3>
                    </div>
                </div>
                <div className="conference-info">
                    <div className="conference-title-card">
                        <div className="conference-title-card-subheader">
                            <h3>Dates</h3>
                        </div>
                        <div className="conference-title-card-header">
                            <h1>7-8 october 2024</h1>
                        </div>
                    </div>
                    <div className="conference-title-card">
                        <div className="conference-title-card-subheader">
                            <h3>Location</h3>
                        </div>
                        <div className="conference-title-card-header">
                            <h1>vienna, austria</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div id="about-container">
                <nav className="about-menu">
                    <ul className="about-ul-menu">
                        <li>
                            <a href="#forum-redirection">forum highlights</a>
                        </li>
                        <li>
                            <a href="#agenda-redirection">programme</a>
                        </li>
                    </ul>
                </nav>
                <div id="forum-redirection" className="about-banner">
                    <div className="about-banner-text">
                        <div className="about-banner-text-title">
                            <h1>Forum highlights</h1>
                        </div>
                        <div className="about-banner-text-body">
                            <div className="left">
                                <div>
                                    The <strong>REINFORCING Open and Responsible Research and Innovation (ORRI) Forum</strong>, held on <strong>October 7 and 8, 2024</strong>, in Vienna, Austria, brought together <strong>86 participants</strong>, including experts, innovators, and practitioners, to explore and shape the future of ORRI. This hybrid event, organized by <strong>Austrian Institute of Technology (AIT)</strong> in collaboration with the REINFORCING Consortium featured both online and onsite activities, fostering collaboration and dialogue across sectors and regions, with a strong focus on the <strong>evolving landscape of responsible research and innovation (RRI)</strong>.
                                </div>
                                <div>
                                    Throughout the event, attendees explored <strong>real-world applications of ORRI practices</strong>. A total of <strong>49 ORRI practices</strong> were collected prior to the forum, and <strong>29 of these were selected for presentation</strong>. These sessions offered valuable insights into <strong>governance, participation, ethics, and sustainability</strong>, giving participants an opportunity to engage with practical examples and learn from ongoing efforts in the field.
                                </div>
                                <div>
                                    The working conference concluded with workshops that <strong>tackled the challenges and barriers to implementing ORRI</strong> on a broader scale. Participants worked together to identify solutions and <strong>shape future REINFORCING funding calls</strong>, targeting critical gaps in ORRI integration. The discussions also highlighted how ORRI can be embedded into future EU research and innovation policies to ensure its long-term sustainability and impact.
                                </div>
                            </div>
                            <div className="right">
                                <div>
                                    The ORRI Forum provided a key platform for reflection, collaboration, and advancing ORRI principles across European institutions, regions, and policies. By facilitating cross-sector dialogue and hands-on exploration of ORRI practices, the event equipped participants with insights and tools to promote responsible, inclusive research and innovation.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="photo-gallery-container">
                <div className="photo-gallery-text-title">
                    <h1>Photo gallery</h1>
                </div>
                <div className="photo-gallery-text-body">
                    <Carousel>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                alt="Image 1"
                                src={image1}
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                alt="Image 2"
                                src={image2}
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                alt="Image 3"
                                src={image3}
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                alt="Image 4"
                                src={image4}
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                alt="Image 5"
                                src={image5}
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                alt="Image 6"
                                src={image6}
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                alt="Image 7"
                                src={image7}
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                alt="Image 8"
                                src={image8}
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                alt="Image 9"
                                src={image9}
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                alt="Image 10"
                                src={image10}
                            />
                        </Carousel.Item>
                    </Carousel>
                </div>
            </div>
            <div id="agenda-container">
                <div id="agenda-redirection" className="content">
                    <div className="agenda-title">
                        <h1>Agenda</h1>
                    </div>
                    <div className="agenda-introduction">
                        <div className="agenda-text">
                            <p>
                                The ORRI forum program featured an engaging mix of interactive workshops and panels, which provided a unique opportunity for participants to delve into groundbreaking research, share valuable insights, and engage in thoughtful discussions. Our sessions will bring together voices from diverse sectors and regions, providing a comprehensive view of the current landscape of responsible science and innovation.
                            </p>
                        </div>
                    </div>
                    <div className="agenda-back-img">
                        <img src={resource} alt="resource" />
                    </div>
                    <div className="agenda-body">
                        <Tab.Container id="conference-tabs" defaultActiveKey="october7">
                            <Row>
                                <Col>
                                    <Nav variant="pills">
                                        <Nav.Item>
                                            <Nav.Link eventKey="october7">
                                                <div className="agenda-schedule-header">
                                                    <div className="agenda-schedule-header-date">
                                                        <h2>7 october</h2>
                                                    </div>
                                                    <div className="agenda-schedule-header-title">
                                                        <h1>online & on site</h1>
                                                    </div>
                                                </div>
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="october8">
                                                <div className="agenda-schedule-header">
                                                    <div className="agenda-schedule-header-date">
                                                        <h2>8 october</h2>
                                                    </div>
                                                    <div className="agenda-schedule-header-title">
                                                        <h1>on site</h1>
                                                    </div>
                                                </div>
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="october7">
                                            <div className="agenda-schedule-body">
                                                <ul>
                                                    <li id="agenda-schedule-body-list-activity">
                                                        <div className="agenda-schedule-body-list-activity-hour">
                                                            <h4>08:30-09:00</h4>
                                                        </div>
                                                        <div className="agenda-schedule-body-list-activity-title">
                                                            <h3>Registration</h3>
                                                        </div>
                                                    </li>
                                                    <li id="agenda-schedule-body-list-activity">
                                                        <div className="agenda-schedule-body-list-activity-hour">
                                                            <h4>09:00-10:00</h4>
                                                        </div>
                                                        <div className="agenda-schedule-body-list-activity-title">
                                                            <h3>Global Network Meeting</h3>
                                                        </div>
                                                    </li>
                                                    <li id="agenda-schedule-body-list-activity">
                                                        <div className="agenda-schedule-body-list-activity-hour">
                                                            <h4>10:00-10:30</h4>
                                                        </div>
                                                        <div className="agenda-schedule-body-list-activity-title">
                                                            <h3>Welcome Coffee</h3>
                                                        </div>
                                                    </li>
                                                    <li id="agenda-schedule-body-list-activity">
                                                        <div className="agenda-schedule-body-list-activity-hour">
                                                            <h4>10:30-11:00</h4>
                                                        </div>
                                                        <div className="agenda-schedule-body-list-activity-title">
                                                            <h3>The REINFORCING Project and the ORRI Global Forum</h3>
                                                        </div>
                                                        <div className="agenda-schedule-body-list-activity-subtitle">
                                                            <h4>Plenary Session (Hybrid) - Room Nordbahnsaal</h4>
                                                            <p>Speakers: Angela Simone and Petra Wagner</p>
                                                        </div>
                                                    </li>
                                                    <li id="agenda-schedule-body-list-activity">
                                                        <div className="agenda-schedule-body-list-activity-hour">
                                                            <h4>11:00-12:30</h4>
                                                        </div>
                                                        <div className="agenda-schedule-body-list-activity-title">
                                                            <h3>Global stORRIes: Presence & Future</h3>
                                                        </div>
                                                        <div className="agenda-schedule-body-list-activity-subtitle">
                                                            <h4>Plenary Session (Hybrid) - Room Nordbahnsaal</h4>
                                                            <p>Speakers: Juliana Chavez-Chaparro, Erik Fisher, Angela Simone, David Winickoff, Masaru Yarime and Peter Biegelbauer<br />
                                                                Five experts from various sectors will discuss the current state of responsible practices in science and innovation with regards to institutions & territories.</p>
                                                        </div>
                                                    </li>
                                                    <li></li>
                                                </ul>
                                                <div className="pararellTitle">
                                                    Parallel working-sessions
                                                </div>
                                                <Tab.Container defaultActiveKey="online">
                                                    <Row id="pararell-tabs" style={{ backgroundColor: '#f4f4f4' }}>
                                                        <Col>
                                                            <Nav variant="pills">
                                                                <Nav.Item>
                                                                    <Nav.Link eventKey="online">
                                                                        <div className="pararell">
                                                                            Online
                                                                        </div>
                                                                    </Nav.Link>
                                                                </Nav.Item>
                                                                <Nav.Item>
                                                                    <Nav.Link eventKey="onsite">
                                                                        <div className="pararell">
                                                                            On site
                                                                        </div>
                                                                    </Nav.Link>
                                                                </Nav.Item>
                                                            </Nav>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Tab.Content>
                                                                <Tab.Pane eventKey="online">
                                                                    <div className="agenda-schedule-body agenda-schedule-body-inner">
                                                                        <ul>
                                                                            <li id="agenda-schedule-body-list-activity">
                                                                                <div className="agenda-schedule-body-list-activity-hour">
                                                                                    <h4>12:30-13:45</h4>
                                                                                </div>
                                                                                <div className="agenda-schedule-body-list-activity-title">
                                                                                    <h3>Online Innovative ORRI Practices in Action</h3>
                                                                                </div>
                                                                                <div className="agenda-schedule-body-list-activity-subtitle">
                                                                                    <h4>Governance & Participation</h4>
                                                                                    <ul>
                                                                                        <li>
                                                                                            Julia Taguena - Importance of ORRI practice for the SDGs
                                                                                        </li>
                                                                                        <li>
                                                                                            David Winickoff - OECD framework for Anticipatory Governance
                                                                                        </li>
                                                                                        <li>
                                                                                            Go Yoshizawa - Shimane Academia
                                                                                        </li>
                                                                                        <li>
                                                                                            Alba Ramirez - UNIQUE project
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </li>
                                                                            <li id="agenda-schedule-body-list-activity">
                                                                                <div className="agenda-schedule-body-list-activity-hour">
                                                                                    <h4>13:45-14:30</h4>
                                                                                </div>
                                                                                <div className="agenda-schedule-body-list-activity-title">
                                                                                    <h3>Lunch break for online participants</h3>
                                                                                </div>
                                                                            </li>
                                                                            <li id="agenda-schedule-body-list-activity">
                                                                                <div className="agenda-schedule-body-list-activity-hour">
                                                                                    <h4>14:30-15:45</h4>
                                                                                </div>
                                                                                <div className="agenda-schedule-body-list-activity-title">
                                                                                    <h3>Ethics & Sustainability</h3>
                                                                                </div>
                                                                                <div className="agenda-schedule-body-list-activity-subtitle">
                                                                                    <ul>
                                                                                        <li>
                                                                                            Anjan Chamuah - KI (India)
                                                                                        </li>
                                                                                        <li>
                                                                                            Mirjana Kranjac - REHEMP
                                                                                        </li>
                                                                                        <li>
                                                                                            Roxane Bibard - The Future Of CCU for Food on the SoScience App (TFOCCUFF)
                                                                                        </li>
                                                                                        <li>
                                                                                            Sousoni Despoina - Industry value from Open COVID-19 data resources
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </Tab.Pane>
                                                                <Tab.Pane eventKey="onsite">
                                                                    <div className="agenda-schedule-body agenda-schedule-body-inner">
                                                                        <ul>
                                                                            <li id="agenda-schedule-body-list-activity">
                                                                                <div className="agenda-schedule-body-list-activity-hour">
                                                                                    <h4>12:30-13:30</h4>
                                                                                </div>
                                                                                <div className="agenda-schedule-body-list-activity-title">
                                                                                    <h3>Lunch Break and Networking</h3>
                                                                                </div>
                                                                                <div className="agenda-schedule-body-list-activity-subtitle">
                                                                                    <h4>Room Foyer</h4>
                                                                                </div>
                                                                            </li>
                                                                            <li id="agenda-schedule-body-list-activity">
                                                                                <div className="agenda-schedule-body-list-activity-hour">
                                                                                    <h4>13:30-15:30</h4>
                                                                                </div>
                                                                                <div className="agenda-schedule-body-list-activity-title">
                                                                                    <h3>Innovative ORRI Practices in Action</h3>
                                                                                </div>
                                                                                <div className="agenda-schedule-body-list-activity-subtitle">
                                                                                    <h4>Governance I - Room Flexraum A</h4>
                                                                                    <ul>
                                                                                        <li>
                                                                                            Thomas Woodson - Inclusion-Immediacy Criterion
                                                                                        </li>
                                                                                        <li>
                                                                                            Michael Ellis - National Research Foundation Engaged Research Framework
                                                                                        </li>
                                                                                        <li>
                                                                                            Nelius Boshoff - RIS-Agric - Research impact system for commodity-based agriculture
                                                                                        </li>
                                                                                    </ul>
                                                                                    <h4>Governance II - Room Flexraum O </h4>
                                                                                    <ul>
                                                                                        <li>
                                                                                            Erik Fisher - Scaling socio-technical dialogues
                                                                                        </li>
                                                                                        <li>
                                                                                            Ingeborg Meijer - SFP - Science fellows in policy
                                                                                        </li>
                                                                                        <li>
                                                                                            Raimundo Roberts - "Responsible Research and Innovation" sources in parliamentary advice
                                                                                        </li>
                                                                                    </ul>
                                                                                    <h4>Ethics - Room Nordbahnsaal</h4>
                                                                                    <ul>
                                                                                        <li>
                                                                                            Teodora Konach - ROSIE
                                                                                        </li>
                                                                                        <li>
                                                                                            Wenzel Mehnert - TechEthos Game
                                                                                        </li>
                                                                                        <li>
                                                                                            Juliana Chaves - UNESCO Recommendation
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </Tab.Pane>
                                                            </Tab.Content>
                                                        </Col>
                                                    </Row>
                                                </Tab.Container>
                                                <ul>
                                                    <li></li>
                                                    <li id="agenda-schedule-body-list-activity">
                                                        <div className="agenda-schedule-body-list-activity-hour">
                                                            <h4>15:30-16:00</h4>
                                                        </div>
                                                        <div className="agenda-schedule-body-list-activity-title">
                                                            <h3>Coffee Break</h3>
                                                        </div>
                                                        <div className="agenda-schedule-body-list-activity-subtitle">
                                                            <h4>Room - Nordbahnsaal & Entrance</h4>
                                                        </div>
                                                    </li>
                                                    <li id="agenda-schedule-body-list-activity">
                                                        <div className="agenda-schedule-body-list-activity-hour">
                                                            <h4>16:00-16:45</h4>
                                                        </div>
                                                        <div className="agenda-schedule-body-list-activity-title">
                                                            <h3>Insights from sharing ORRI practices</h3>
                                                        </div>
                                                        <div className="agenda-schedule-body-list-activity-subtitle">
                                                            <h4>Plenary Session (Hybrid) - Room Nordbahnsaal</h4>
                                                        </div>
                                                    </li>
                                                    <li id="agenda-schedule-body-list-activity">
                                                        <div className="agenda-schedule-body-list-activity-hour">
                                                            <h4>16.45-17.15</h4>
                                                        </div>
                                                        <div className="agenda-schedule-body-list-activity-title">
                                                            <h3>REINFORCING Platform launch</h3>
                                                        </div>
                                                        <div className="agenda-schedule-body-list-activity-subtitle">
                                                            <h4>Plenary Session (Hybrid) - Room Nordbahnsaal</h4>
                                                            <p>
                                                                Speakers: Raúl Tabarés and Rodrigo Conde-Jimenez<br />
                                                                Insights about REINFORCING’s online ORRI platform, a unique one-stop-source for tested resources, tailored services and funding calls.
                                                            </p>
                                                        </div>
                                                    </li>
                                                    <li id="agenda-schedule-body-list-activity">
                                                        <div className="agenda-schedule-body-list-activity-hour">
                                                            <h4>17:15-17:30</h4>
                                                        </div>
                                                        <div className="agenda-schedule-body-list-activity-title">
                                                            <h3>Wrap up of Day 1</h3>
                                                        </div>
                                                        <div className="agenda-schedule-body-list-activity-subtitle">
                                                            <h4>Plenary Session (Hybrid) - Room Nordbahnsaal</h4>
                                                        </div>
                                                    </li>
                                                    <li id="agenda-schedule-body-list-activity">
                                                        <div className="agenda-schedule-body-list-activity-hour">
                                                            <h4>17:30-19:00</h4>
                                                        </div>
                                                        <div className="agenda-schedule-body-list-activity-title">
                                                            <h3>Reception with food & drinks</h3>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="october8"><div className="agenda-schedule-body">
                                            <ul>
                                                <li id="agenda-schedule-body-list-activity">
                                                    <div className="agenda-schedule-body-list-activity-hour">
                                                        <h4>9:00-9:15</h4>
                                                    </div>
                                                    <div className="agenda-schedule-body-list-activity-title">
                                                        <h3>Welcome</h3>
                                                    </div>
                                                    <div className="agenda-schedule-body-list-activity-subtitle">
                                                        <h4>Plenary Session - Room Nordbahnsaal</h4>
                                                        <p>Speakers: Angela Simone and Petra Wagner</p>
                                                    </div>
                                                </li>
                                                <li id="agenda-schedule-body-list-activity">
                                                    <div className="agenda-schedule-body-list-activity-hour">
                                                        <h4>9:15-11:15</h4>
                                                    </div>
                                                    <div className="agenda-schedule-body-list-activity-title">
                                                        <h3>Innovative ORRI Practices in Action</h3>
                                                    </div>
                                                    <div className="agenda-schedule-body-list-activity-subtitle">
                                                        <h4>Parallel working sessions</h4>
                                                        <h4>Governance III - Room Nordbahnsaal</h4>
                                                        <ul>
                                                            <li>
                                                                Simon Fielke - Ag2050: Scenario building for foresight identified a lack of Indigenous perspectives
                                                            </li>
                                                            <li>
                                                                Anna Pellizzone - InformAria
                                                            </li>
                                                            <li>
                                                                Cecilia Gaballo - Citizens’ Jury
                                                            </li>
                                                            <li>
                                                                Dragana Ubiparip Samek - CoCOI FoodTech PATH
                                                            </li>
                                                        </ul>
                                                        <h4>Experimental Settings / Labs - Room Flexraum A</h4>
                                                        <ul>
                                                            <li>
                                                                Rene von Schomberg - ORRI Living Lab
                                                            </li>
                                                            <li>
                                                                Mila Grahovac - RRIzing Lab
                                                            </li>
                                                            <li>
                                                                Masaru Yarime - AI Ethics and Governance Lab (AIEGL)
                                                            </li>
                                                            <li>
                                                                Marta Corubolo - Transitioning Design Orienting Scenario for the Milanese Food System
                                                            </li>
                                                        </ul>
                                                        <h4>ORRI in general - Room Flexraum O</h4>
                                                        <ul>
                                                            <li>
                                                                Ralf Lindner - RRI Thinking Tool
                                                            </li>
                                                            <li>
                                                                Laura Mentini (APRE)  &  Stefania Laneve  - Capacity Building Acceleration Service for European HEIs (CBAS4HEIs)
                                                            </li>
                                                            <li>
                                                                Stefania Laneve & Teodora Konach - Piloting open and responsible Activities and Trainings Towards the Enhancement of Researchers Networks (PATTERN)
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </li>
                                                <li id="agenda-schedule-body-list-activity">
                                                    <div className="agenda-schedule-body-list-activity-hour">
                                                        <h4>11:15-12:00</h4>
                                                    </div>
                                                    <div className="agenda-schedule-body-list-activity-title">
                                                        <h3>Insights from sharing ORRI Practices </h3>
                                                    </div>
                                                    <div className="agenda-schedule-body-list-activity-subtitle">
                                                        <h4>Plenary Session - Room Nordbahnsaal</h4>
                                                    </div>
                                                </li>
                                                <li id="agenda-schedule-body-list-activity">
                                                    <div className="agenda-schedule-body-list-activity-hour">
                                                        <h4>12:00-13:00</h4>
                                                    </div>
                                                    <div className="agenda-schedule-body-list-activity-title">
                                                        <h3>Lunch Break and Networking</h3>
                                                    </div>
                                                    <div className="agenda-schedule-body-list-activity-subtitle">
                                                        <h4>Room Foyer</h4>
                                                    </div>
                                                </li>
                                                <li id="agenda-schedule-body-list-activity">
                                                    <div className="agenda-schedule-body-list-activity-hour">
                                                        <h4>13.00-13.30</h4>
                                                    </div>
                                                    <div className="agenda-schedule-body-list-activity-title">
                                                        <h3>Experiencing an Integrated Living and Co-Working Community</h3>
                                                    </div>
                                                    <div className="agenda-schedule-body-list-activity-subtitle">
                                                        <h4>Meet in the Foyer</h4>
                                                        <p>
                                                            A guided journey through an innovative and sustainable local development hub.
                                                        </p>
                                                    </div>
                                                </li>
                                                <li id="agenda-schedule-body-list-activity">
                                                    <div className="agenda-schedule-body-list-activity-hour">
                                                        <h4>13:30-14:30</h4>
                                                    </div>
                                                    <div className="agenda-schedule-body-list-activity-title">
                                                        <h3>Why are we still struggling to mainstream ORRI: main challenges and gaps</h3>
                                                    </div>
                                                    <div className="agenda-schedule-body-list-activity-subtitle">
                                                        <h4>Workshop - Room Nordbahnsaal</h4>
                                                        <p>
                                                            This workshop tackles unresolved challenges in ORRI, while allowing participants to shape future REINFORCING funding calls.
                                                        </p>
                                                    </div>
                                                </li>
                                                <li id="agenda-schedule-body-list-activity">
                                                    <div className="agenda-schedule-body-list-activity-hour">
                                                        <h4>14:30-14:45</h4>
                                                    </div>
                                                    <div className="agenda-schedule-body-list-activity-title">
                                                        <h3>Coffee Break</h3>
                                                    </div>
                                                    <div className="agenda-schedule-body-list-activity-subtitle">
                                                        <h4>Room Nordbahnsaal & Entrance</h4>
                                                    </div>
                                                </li>
                                                <li id="agenda-schedule-body-list-activity">
                                                    <div className="agenda-schedule-body-list-activity-hour">
                                                        <h4>14:45-15:45</h4>
                                                    </div>
                                                    <div className="agenda-schedule-body-list-activity-title">
                                                        <h3>ORRI opportunities and challenges for European research and innovation policy recommendations</h3>
                                                    </div>
                                                    <div className="agenda-schedule-body-list-activity-subtitle">
                                                        <h4>Workshop - Room Nordbahnsaal</h4>
                                                        <p>
                                                            Discussion of key challenges and opportunities for keeping the ORRI approach central to future EU R&I policies.
                                                        </p>
                                                    </div>
                                                </li>
                                                <li id="agenda-schedule-body-list-activity">
                                                    <div className="agenda-schedule-body-list-activity-hour">
                                                        <h4>15:45-16:00</h4>
                                                    </div>
                                                    <div className="agenda-schedule-body-list-activity-title">
                                                        <h3>Wrap up and Closing the Forum</h3>
                                                    </div>
                                                    <div className="agenda-schedule-body-list-activity-subtitle">
                                                        <h4>Plenary Session - Room Nordbahnsaal</h4>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div></Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </div>
                </div>
            </div>
            <div id="submission-container">
                <div id="call-redirection" className="content">
                    <div className="speakers-section">
                        <div className="speakers-title">
                            <h1>Speakers</h1>
                        </div>
                        <div className="speakers-body">
                            <div className="speakers-column">
                                <div className="speakers-card" id="speakers-card-row-1">
                                    <div className="speakers-card-column">
                                        <div className="speakers-card-img">
                                            <img src={speaker_1} alt="angela_simone" />
                                        </div>
                                    </div>
                                    <div className="speakers-card-column">
                                        <div className="speakers-card-title">
                                            <h2>Angela Simone</h2>
                                            <a className="btn-social-media" href="https://www.linkedin.com/in/angela-simone-b268a56/" target="_blank" rel="noreferrer">
                                                <span className="social-media-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
                                                        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401m-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4" />
                                                    </svg>
                                                </span>
                                            </a>
                                        </div>
                                        <div className="speakers-card-body">
                                            <h4>
                                                REINFORCING Coordinator on behalf of Bassetti
                                                Foundation ETS. Expert member of the EU Commission
                                                MLE on Public Engagement in R&I. Adjunct Professor
                                                in RRI at the POLIMI Graduate School of Management.
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="speakers-card" id="speakers-card-row-2">
                                    <div className="speakers-card-column">
                                        <div className="speakers-card-img">
                                            <img src={speaker_2} alt="david_winickoff" />
                                        </div>
                                    </div>
                                    <div className="speakers-card-column">
                                        <div className="speakers-card-title">
                                            <h2>David Winickoff</h2>
                                            <a className="btn-social-media" href="https://www.linkedin.com/in/david-winickoff-b9762271/?originalSubdomain=fr" target="_blank" rel="noreferrer">
                                                <span className="social-media-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
                                                        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401m-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4" />
                                                    </svg>
                                                </span>
                                            </a>
                                        </div>
                                        <div className="speakers-card-body">
                                            <h4>
                                                Head of the Responsible Innovation Unit at the
                                                OECD in Paris, David oversees policy on science
                                                and technology. He has over 60 publications and
                                                teaches at SciencesPo Law School. He is a graduate
                                                of Harvard, Yale, and Cambridge, and has served on
                                                expert panels for various global institutions.
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="speakers-card" id="speakers-card-row-3">
                                    <div className="speakers-card-column">
                                        <div className="speakers-card-img">
                                            <img src={speaker_7} alt="petra_wagner" />
                                        </div>
                                    </div>
                                    <div className="speakers-card-column" id="speakers-card-row-1">
                                        <div className="speakers-card-title">
                                            <h2>Petra Wagner</h2>
                                            <a className="btn-social-media" href="https://www.linkedin.com/in/petra-wagner-9a349420/ " target="_blank" rel="noreferrer">
                                                <span className="social-media-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
                                                        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401m-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4" />
                                                    </svg>
                                                </span>
                                            </a>
                                        </div>
                                        <div className="speakers-card-body">
                                            <h4>
                                                Deputy Head of the Center for Innovation Systems and Policy at AIT Austrian Institute of Technology. Senior Expert Advisor on transformative innovation policies and strategies.
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="speakers-card" id="speakers-card-row-3">
                                    <div className="speakers-card-column">
                                        <div className="speakers-card-img">
                                            <img src={speaker_3} alt="erik_fisher" />
                                        </div>
                                    </div>
                                    <div className="speakers-card-column">
                                        <div className="speakers-card-title">
                                            <h2>Erik Fisher</h2>
                                            <a className="btn-social-media" href="https://www.linkedin.com/in/fishererik/ " target="_blank" rel="noreferrer">
                                                <span className="social-media-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
                                                        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401m-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4" />
                                                    </svg>
                                                </span>
                                            </a>
                                        </div>
                                        <div className="speakers-card-body">
                                            <h4>
                                                Associate professor at Arizona State University,
                                                directing the Center for Responsible Innovation
                                                and leading the Public Interest Technology MS
                                                program. He edits the Journal of Responsible
                                                Innovation and has developed Socio-Technical
                                                Integration Research for over 80 organizations.
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="speakers-column">
                                <div className="speakers-card" id="speakers-card-row-1">
                                    <div className="speakers-card-column">
                                        <div className="speakers-card-img">
                                            <img src={speaker_4} alt="peter_biegelbauer" />
                                        </div>
                                    </div>
                                    <div className="speakers-card-column">
                                        <div className="speakers-card-title">
                                            <h2>Peter Biegelbauer</h2>
                                            <a className="btn-social-media" href="https://www.linkedin.com/in/peter-biegelbauer-ba4b72/?original_referer=https%3A%2F%2Fwww%2Egoogle%2Ecom%2F&originalSubdomain=at " target="_blank" rel="noreferrer">
                                                <span className="social-media-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
                                                        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401m-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4" />
                                                    </svg>
                                                </span>
                                            </a>
                                        </div>
                                        <div className="speakers-card-body">
                                            <h4>
                                                Senior Scientist at the Center for Innovation Systems
                                                & Policy at the Austrian Institute of Technology in
                                                Vienna (AIT). He leads the AI Ethics Lab, focusing on
                                                AI Ethics and Innovation. He has coordinated research
                                                projects on policy analysis and responsible innovation.
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="speakers-card" id="speakers-card-row-2">
                                    <div className="speakers-card-column">
                                        <div className="speakers-card-img">
                                            <img src={speaker_5} alt="masaru_yarime" />
                                        </div>
                                    </div>
                                    <div className="speakers-card-column">
                                        <div className="speakers-card-title">
                                            <h2>Masaru Yarime</h2>
                                            <a className="btn-social-media" href="https://www.linkedin.com/in/yarimemasaru/" target="_blank" rel="noreferrer">
                                                <span className="social-media-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
                                                        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401m-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4" />
                                                    </svg>
                                                </span>
                                            </a>
                                        </div>
                                        <div className="speakers-card-body">
                                            <h4>
                                                Associate Professor at HKUST, he, is also a researcher
                                                of science, technology, and innovation policy for
                                                sustainability, focusing on AI, IoT, blockchain, and
                                                smart cities. He serves on editorial boards and holds
                                                degrees from Tokyo, Caltech, and Maastricht.
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="speakers-card" id="speakers-card-row-1">
                                    <div className="speakers-card-column">
                                        <div className="speakers-card-img">
                                            <img src={speaker_8} alt="cornelia_reiter" />
                                        </div>
                                    </div>
                                    <div className="speakers-card-column">
                                        <div className="speakers-card-title">
                                            <h2>Cornelia Reiter</h2>
                                            <a className="btn-social-media" href="https://www.linkedin.com/in/mag-cornelia-reiter-ma-49685754/?originalSubdomain=at" target="_blank" rel="noreferrer">
                                                <span className="social-media-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
                                                        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401m-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4" />
                                                    </svg>
                                                </span>
                                            </a>
                                        </div>
                                        <div className="speakers-card-body">
                                            <h4>
                                                Scientist at the Center for Innovation Systems and Policy at AIT Austrian Institute of Technology.
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="speakers-card">
                                    <div className="speakers-card-column">
                                        <div className="speakers-card-img">
                                            <img src={speaker_6} alt="juliana_chaves" />
                                        </div>
                                    </div>
                                    <div className="speakers-card-column">
                                        <div className="speakers-card-title">
                                            <h2>Juliana Chaves</h2>
                                            <a className="btn-social-media" href="https://www.linkedin.com/in/juliana-chaves-chaparro-576b6213/?locale=en_US" target="_blank" rel="noreferrer">
                                                <span className="social-media-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
                                                        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401m-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4" />
                                                    </svg>
                                                </span>
                                            </a>
                                        </div>
                                        <div className="speakers-card-body">
                                            <h4>
                                                Environmental, STI and gender specialist. She is currently a senior consultant at <a target="_blank" rel="noreferrer" href="https://www.unesco.org/en/management-social-transformations-most-programme">UNESCO MOST Section</a>
                                                in charge of the Horizon Europe <a target="_blank" rel="noreferrer" href="https://opusproject.eu/">Open Universal Science (OPUS)</a> project. With more than 20 years of international working experience in the promotion of the science-policy-society interface
                                                in various regions, and particularly in the Global South. She has been coordinator, co-editor and co-author of the
                                                <a target="_blank" rel="noreferrer" href="https://unesdoc.unesco.org/ark:/48223/pf0000368900">"Co-designing science in Africa"</a> UNESCO publication,
                                                among others. She is the current President of the NGO <a target="_blank" rel="noreferrer" href="https://mixtura-ambiental.org/">Mixtura ambiental</a> and VERITY advisory board member.
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ContactUs />
            <SocialAndNewsletter />
            <Footer />
        </div>
    )
}