import backgroungLogo from "../../images/call-for-evaluators/call_for_evaluators_bg_1.svg";
import remunerationIcon from "../../images/call-for-evaluators/remuneration_icon.svg";
import ContactUsCallForEvaluators from "../../components/ContactUsCallForEvaluators";
import SocialAndNewsletter from "../../components/SocialAndNewsletter";
import icon1 from "../../images/call-for-evaluators/icon1.svg";
import icon2 from "../../images/call-for-evaluators/icon2.svg";
import icon3 from "../../images/call-for-evaluators/icon3.svg";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

import "./CallForEvaluatorsPage.css";

export default function CallForEvaluatorsPage() {
    return (
        <div className="page-container grants">
            <Header className="header-grants-oc" />
            <div id="title">
                <div className="content">
                    <div className="title-subtitle">Open Calls</div>
                    <div className="title-title">Call for evaluators</div>
                </div>
            </div>
            <div className="evaluators-breadcrumb" id="breadcrumb">
                <div className="content">
                    Home / Open Calls / Call for evaluators
                </div>
            </div>
            <div id="content">
                <div id="cfe-header">
                    <div className="content">
                        <div className="cfe-schema">
                            <div className="cfe-body">
                                <div className="cfe-1">
                                    REINFORCING project is looking for experts with experience in
                                    the implementation of ORRI projects and initiatives in order
                                    to assist REINFORCING in the evaluation of project proposals.
                                </div>
                                <div className="cfe-2">
                                    The external experts will be selected through an <u>Open Call
                                    for Evaluators</u>, which will be open on the REINFORCING
                                    website from <strong>November 2023 to December 2025</strong>,  
                                    to ensure the best possible expertise in the evaluation process,
                                    so that grant applications are assessed in a transparent and
                                    consistent way.
                                </div>
                                <div className="cfe-3">
                                    Please note that submitting your candidature doesn’t automatically
                                    imply that you will be selected as an evaluator for one of the 7
                                    REINFORCING Open Calls. Potential evaluators will be selected by
                                    the Consortium according to their experience in the topic of each
                                    specific call and contacted before the call launches to check
                                    their availability.
                                </div>
                            </div>
                            <div className="cfe-logo">
                                <div className="cfe-background">
                                    <img src={backgroungLogo} alt="reinforcing" />
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div id="current-calls-objectives">
                    <div className="content">
                        <div className="title-2">
                            What are the tasks for external evaluators?
                        </div>
                        <div className="body">
                            Expert evaluators are asked to assist REINFORCING partners in evaluating
                            the grant applications. Evaluators will work in their personal capacity
                            as experts in implementing ORRI initiatives, especially in the different
                            topics of the calls for proposals. As a general rule, the proposals
                            submitted under the same call will be evaluated by the same panel of experts.
                        </div>
                        <div className="process">
                            Evaluators will work remotely and the evaluation process will consist of:
                        </div>
                        <div className="cc-steps">
                            <div className="cc-step-1">
                                <img src={icon1} alt="icon" id="cc-step-1-icon" />
                                <span id="summary-text">
                                    An <strong>online briefing</strong> with the REINFORCING Consortium
                                </span>
                            </div>
                            <div className="cc-step-2">
                                <img src={icon2} alt="icon" id="cc-step-2-icon" />
                                <span id="summary-text-2">
                                    An <strong>initial individual evaluation</strong>, carried out
                                    independently and remotely by each expert of the evaluation panel
                                </span>
                            </div>
                            <div className="cc-step-3">
                                <img src={icon3} alt="icon" id="cc-step-3-icon" />
                                <span id="summary-text-3">
                                    An <strong>online panel assessment</strong>, where
                                    experts reach an agreement on the proposal evaluation
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="remuneration">
                    <div className="content">
                        <div className="remuneration-header">
                            <div className="remuneration-header-1">
                                <div id="remuneration-header-title">
                                    Are external experts remunerated?
                                </div>
                            </div>
                            <div className="remuneration-header-2">
                                <div id="remuneration-header-icon">
                                    <img src={remunerationIcon} alt="icon" />
                                </div>
                            </div>
                            <div className="remuneration-header-3">
                                <div id="remuneration-header-text">
                                    <strong>Yes</strong>, external evaluators will receive a fee in line 
                                    with the European Commission standards remuneration for external experts.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <div className="remuneration-card">
                            <div className="remuneration-card-structure">
                                <div className="remuneration-card-body">
                                    <div id="remuneration-card-body-title">
                                        How to apply to become an external evaluator?
                                    </div>
                                    <div id="remuneration-card-body-subtitle">
                                        If you are interested in working as an external expert assisting in
                                        the evaluation of REINFORCING cascading grants, you can declare your
                                        interest by submitting your application online
                                    </div>
                                    <div id="remuneration-card-body-text">
                                        Besides completing basic information online regarding your expertise,
                                        you will be asked to upload your full CV. 
                                    </div>
                                    <div id="remuneration-card-body-buttons">
                                        <a href="/Open call for external evaluators.pdf" id="apply-btn" download>
                                            download the Open call for evaluators &nbsp;&nbsp;
                                            <span className="button-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                                                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                                                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                                                </svg>
                                            </span>
                                        </a>
                                        <a href="/open-calls/calls-evaluation/apply" id="apply-btn-2">
                                            Apply here! &nbsp;&nbsp;
                                            <span className="button-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                                                </svg>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                                <div className="remuneration-card-dates">
                                    <div id="remuneration-card-dates-structure">
                                        <div id="remuneration-card-dates-title">
                                            The call will be open from:
                                        </div>
                                        <div id="remuneration-card-dates-month">
                                            November
                                        </div>
                                        <div id="remuneration-card-dates-year">
                                            2023
                                        </div>
                                        <div id="remuneration-card-dates-title-2">
                                            To:
                                        </div>
                                        <div id="remuneration-card-dates-month">
                                            December
                                        </div>
                                        <div id="remuneration-card-dates-year">
                                            2025
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ContactUsCallForEvaluators />
            <SocialAndNewsletter />
            <Footer />
        </div>
    ); 
}