import logoEurope from '../flag.jpg';
import "./Footer.css";

export default function Footer() {
    return (
        <footer>
            <div className="up-footer">
                <div className='content'>
                    <div className="left">
                        Funded by the European Union. Views and opinions expressed are however those
                        of the author(s) only and do not necessarily reflect those of the European
                        Union or the REA. Neither the European Union nor the granting authority can be
                        held responsible for them.
                    </div>
                    <div className="right">
                        <img src={logoEurope} alt="reinforcing european union" />
                    </div>
                </div>
            </div>
            <div className="sub-footer">
                <div className='content'>
                    <div className="left">
                        © 2023 Reinforcing project
                    </div>
                    <div className="right">
                        <a href="/Privacy policy.pdf" download className="footer-link">Privacy policy</a>
                        |
                        <a href="/Cookies policy.pdf" download className="footer-link">Cookie policy</a>
                    </div>
                </div>
            </div>
        </footer >
    );
}