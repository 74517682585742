import pattern from "../../../images/oss-platform/sibling/pattern.jpg";
import coara from "../../../images/oss-platform/sibling/coara.jpg";
import ecs from "../../../images/oss-platform/sibling/ecs.png";
import impetus from "../../../images/oss-platform/sibling/impetus.jpg";
import irise from "../../../images/oss-platform/sibling/irise.jpg";
import osiris from "../../../images/oss-platform/sibling/osiris.jpg";
import tier2 from "../../../images/oss-platform/sibling/tier2.jpg";
import futureresilience from "../../../images/oss-platform/sibling/future.jpg";
import coalesce from "../../../images/oss-platform/sibling/coalesce.jpg";
import { FaArrowLeft } from "react-icons/fa6";
import { useLocation } from 'react-router-dom';
import "./OSSPlatformServicesSlibingPage.css";

export function OSSPlatformServicesSlibingPage() {
    
    const location = useLocation();
    
    return (
        <>
            {location.pathname === "/oss-platform/services/orri-community/slibing-projects-platforms" && (
                <>
                    <div className="breadcrumb">
                        Orri Community / <span>Sibling Projects & Platform</span>
                    </div>
                    <a className="back" href="/oss-platform/services/orri-community">
                        <FaArrowLeft />Back to ORRI Community
                    </a>
                    <div className="sibling-title">
                        Sibling Projects & Platforms
                    </div>
                    <div className="sibling-elements">
                        <div className="sibling-element">
                            <div className="sibling-element-logo">
                                <div className="sibling-element-circle">
                                    <img src={pattern} alt="pattern" />
                                </div>
                            </div>
                            <div className="sibling-element-body">
                                <div className="sibling-element-title">
                                    <h3>PATTERN (Piloting open and responsible Activities and Trainings Towards the Enhancement of Researchers Networks)</h3>
                                </div>
                                <div className="sibling-element-text">
                                    <p>
                                        To promote the practice of Open and Responsible Research and Innovation...
                                    </p>
                                    <p>
                                        <a href="/oss-platform/services/orri-community/slibing-projects-platforms/coara"
                                            className="sibling-element-link">View more →</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="sibling-element">
                            <div className="sibling-element-logo">
                                <div className="sibling-element-circle">
                                    <img src={coara} alt="coara" />
                                </div>
                            </div>
                            <div className="sibling-element-body">
                                <div className="sibling-element-title">
                                    <h3>COARA (Coalition for Advancing Research Assessment)</h3>
                                </div>
                                <div className="sibling-element-text">
                                    <p>
                                        The Horizon Europe CoARA Boost project aims to enhance the operational...
                                    </p>
                                    <p>
                                        <a href="/oss-platform/services/orri-community/slibing-projects-platforms/coara"
                                            className="sibling-element-link">View more →</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="sibling-element">
                            <div className="sibling-element-logo">
                                <div className="sibling-element-circle">
                                    <img src={ecs} alt="ecs" style={{ marginTop: '35px' }} />
                                </div>
                            </div>
                            <div className="sibling-element-body">
                                <div className="sibling-element-title">
                                    <h3>ECS (European Citizen Science)</h3>
                                </div>
                                <div className="sibling-element-text">
                                    <p>
                                        European Citizen Science aims to create a globally connected, inclusive and...
                                    </p>
                                    <p>
                                        <a href="/oss-platform/services/orri-community/slibing-projects-platforms/ecs"
                                            className="sibling-element-link">View more →</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="sibling-element">
                            <div className="sibling-element-logo">
                                <div className="sibling-element-circle">
                                    <img src={impetus} alt="impetus" />
                                </div>
                            </div>
                            <div className="sibling-element-body">
                                <div className="sibling-element-title">
                                    <h3>IMPETUS</h3>
                                </div>
                                <div className="sibling-element-text">
                                    <p>
                                        IMPETUS will support and give recognition to citizen science (CS) by...
                                    </p>
                                    <p>
                                        <a href="/oss-platform/services/orri-community/slibing-projects-platforms/impetus"
                                            className="sibling-element-link">View more →</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="sibling-element">
                            <div className="sibling-element-logo">
                                <div className="sibling-element-circle">
                                    <img src={irise} alt="irise" />
                                </div>
                            </div>
                            <div className="sibling-element-body">
                                <div className="sibling-element-title">
                                    <h3>iRISE (Improving Reproducibility In SciencE)</h3>
                                </div>
                                <div className="sibling-element-text">
                                    <p>
                                        The iRISE (improving Reproducibility In SciencE) Project is a Horizon Europe...
                                    </p>
                                    <p>
                                        <a href="/oss-platform/services/orri-community/slibing-projects-platforms/irise"
                                            className="sibling-element-link">View more →</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="sibling-element">
                            <div className="sibling-element-logo">
                                <div className="sibling-element-circle">
                                    <img src={osiris} alt="osiris" style={{ width: '80px', marginTop: '13px', marginLeft: '13px' }} />
                                </div>
                            </div>
                            <div className="sibling-element-body">
                                <div className="sibling-element-title">
                                    <h3>OSIRIS (Open Science to Increase Reproducibility in Science)</h3>
                                </div>
                                <div className="sibling-element-text">
                                    <p>
                                        The OSIRIS (Open Science to Increase Reproducibility in Science) project is...
                                    </p>
                                    <p>
                                        <a href="/oss-platform/services/orri-community/slibing-projects-platforms/osiris"
                                            className="sibling-element-link">View more →</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="sibling-element">
                            <div className="sibling-element-logo">
                                <div className="sibling-element-circle">
                                    <img src={tier2} alt="tier2" style={{ width: '80px', marginTop: '13px', marginLeft: '13px', borderRadius: '10px' }} />
                                </div>
                            </div>
                            <div className="sibling-element-body">
                                <div className="sibling-element-title">
                                    <h3>Tier2</h3>
                                </div>
                                <div className="sibling-element-text">
                                    <p>
                                        Through the collaboration of experts from 10 partner institutions from 7...
                                    </p>
                                    <p>
                                        <a href="/oss-platform/services/orri-community/slibing-projects-platforms/tier2"
                                            className="sibling-element-link">View more →</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="sibling-element">
                            <div className="sibling-element-logo">
                                <div className="sibling-element-circle">
                                    <img src={futureresilience} alt="futureResilience" />
                                </div>
                            </div>
                            <div className="sibling-element-body">
                                <div className="sibling-element-title">
                                    <h3>FutureResilience</h3>
                                </div>
                                <div className="sibling-element-text">
                                    <p>
                                        The FUTURESILIENCE project seeks to strengthen Europe's economic and...
                                    </p>
                                    <p>
                                        <a href="/oss-platform/services/orri-community/slibing-projects-platforms/futureresilience"
                                            className="sibling-element-link">View more →</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="sibling-element">
                            <div className="sibling-element-logo">
                                <div className="sibling-element-circle">
                                    <img src={coalesce} alt="coalesce" />
                                </div>
                            </div>
                            <div className="sibling-element-body">
                                <div className="sibling-element-title">
                                    <h3>COALESCE (Coordinated Opportunities for Advanced Leadership and Engagement in Science Communication in Europe) </h3>
                                </div>
                                <div className="sibling-element-text">
                                    <p>
                                        COALESCE is a four-year, EU-funded initiative designed to advance...
                                    </p>
                                    <p>
                                        <a href="/oss-platform/services/orri-community/slibing-projects-platforms/coalesce"
                                            className="sibling-element-link">View more →</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {location.pathname === "/oss-platform/services/orri-community/slibing-projects-platforms/pattern" && (
                <div className="services-tabs-body-data services-tabs-body-data-slibing">
                    <div className="breadcrumb">
                        Orri Community / Sibling Projects & Platform / <span>Pattern</span>
                    </div>
                    <a className="back" href="/oss-platform/services/orri-community/slibing-projects-platforms">
                        <FaArrowLeft />Back to projects
                    </a>
                    <div className="slibing-container">
                        <div className="left">
                            <div className="slibing-title">
                                Pattern
                            </div>
                            <div className="slibing-summary">
                                <p>
                                    To promote the practice of Open and Responsible Research and Innovation (Open RRI) by developing and piloting training activities for researchers at all stages of their careers.
                                </p>
                                <p>
                                    These trainings, strengthening researchers’ transferable skills, will empower Higher Education Institutions (HEIs) and research organizations to embrace a transformative process to improve the excellence of the science conducted, the capacity within the European Research Area (ERA) to tackle societal challenges and the interaction between science and society.
                                </p>
                            </div>
                        </div>
                        <div className="right">
                            <div className="image">
                                <img src={pattern} alt="pattern" />
                            </div>
                            <div className="duration">
                                <div className="duration-title">
                                    Duration of the project
                                </div>
                                <div className="duration-text">
                                    1 January 2023 - 30 June 2026
                                </div>
                            </div>
                            <div className="more-info">
                                <div className="more-info-title">
                                    More information
                                </div>
                                <div className="more-info-text">
                                    <a href="https://www.pattern-openresearch.eu/" target="_blank" rel="noreferrer">https://www.pattern-openresearch.eu/ </a>
                                </div>
                                <div className="social">
                                    <a href="https://www.linkedin.com/company/patterneu/" target="_blank" rel="noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 30 30">
                                            <path d="M9,25H4V10h5V25z M6.501,8C5.118,8,4,6.879,4,5.499S5.12,3,6.501,3C7.879,3,9,4.121,9,5.499C9,6.879,7.879,8,6.501,8z M27,25h-4.807v-7.3c0-1.741-0.033-3.98-2.499-3.98c-2.503,0-2.888,1.896-2.888,3.854V25H12V9.989h4.614v2.051h0.065 c0.642-1.18,2.211-2.424,4.551-2.424c4.87,0,5.77,3.109,5.77,7.151C27,16.767,27,25,27,25z"></path>
                                        </svg>
                                    </a>
                                    <a href="https://x.com/PatternEU" target="_blank" rel="noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-twitter-x" viewBox="0 0 16 16">
                                            <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {location.pathname === "/oss-platform/services/orri-community/slibing-projects-platforms/coara" && (
                <div className="services-tabs-body-data services-tabs-body-data-slibing">
                    <div className="breadcrumb">
                        Orri Community / Sibling Projects & Platform / <span>Coara</span>
                    </div>
                    <a className="back" href="/oss-platform/services/orri-community/slibing-projects-platforms">
                        <FaArrowLeft />Back to projects
                    </a>
                    <div className="slibing-container">
                        <div className="left">
                            <div className="slibing-title">
                                COARA (Coalition for Advancing Research Assessment)
                            </div>
                            <div className="slibing-summary">
                                <p>
                                    The Horizon Europe CoARA Boost project aims to enhance the operational capacity of CoARA and support research assessment reforms. Running for 36 months since October 2023, the project redistributes over half of its budget to institutions through cascade funding, supporting at least 50 projects. Key actions include strengthening operational support, sharing knowledge, raising awareness, and assisting Working Groups in exploring new models of research assessment. The initiative focuses on driving institutional changes to recognize diverse research outputs, expanding CoARA’s global reach, and promoting international cooperation in research assessment reform.
                                </p>
                            </div>
                        </div>
                        <div className="right">
                            <div className="image">
                                <img src={coara} alt="coara" />
                            </div>
                            <div className="duration">
                                <div className="duration-title">
                                    Duration of the project
                                </div>
                                <div className="duration-text">
                                    1 October 2023- 30 September 2026
                                </div>
                            </div>
                            <div className="more-info">
                                <div className="more-info-title">
                                    More information
                                </div>
                                <div className="more-info-text">
                                    <a href="https://coara.eu/coalition/coara-boost-project/" rel="noreferrer" target="_blank">https://coara.eu/coalition/coara-boost-project/ </a>
                                </div>
                                <div className="social">
                                    <a href="https://www.linkedin.com/company/coarassessment/" target="_blank" rel="noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 30 30">
                                            <path d="M9,25H4V10h5V25z M6.501,8C5.118,8,4,6.879,4,5.499S5.12,3,6.501,3C7.879,3,9,4.121,9,5.499C9,6.879,7.879,8,6.501,8z M27,25h-4.807v-7.3c0-1.741-0.033-3.98-2.499-3.98c-2.503,0-2.888,1.896-2.888,3.854V25H12V9.989h4.614v2.051h0.065 c0.642-1.18,2.211-2.424,4.551-2.424c4.87,0,5.77,3.109,5.77,7.151C27,16.767,27,25,27,25z"></path>
                                        </svg>
                                    </a>
                                    <a href="https://x.com/search?q=%23ReformingRA&src=typed_query&f=live" target="_blank" rel="noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-twitter-x" viewBox="0 0 16 16">
                                            <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {location.pathname === "/oss-platform/services/orri-community/slibing-projects-platforms/ecs" && (
                <div className="services-tabs-body-data services-tabs-body-data-slibing">
                    <div className="breadcrumb">
                        Orri Community / Sibling Projects & Platform / <span>ECS (European Citizen Science)</span>
                    </div>
                    <a className="back" href="/oss-platform/services/orri-community/slibing-projects-platforms">
                        <FaArrowLeft />Back to projects
                    </a>
                    <div className="slibing-container">
                        <div className="left">
                            <div className="slibing-title">
                                ECS (European Citizen Science)
                            </div>
                            <div className="slibing-summary">
                                <p>
                                    European Citizen Science aims to create a globally connected, inclusive and strong citizen science community for societal change in Europe. The European Citizen Science project has the overall objective to widen and strengthen the citizen science community in Europe through capacity building and awareness raising activities, such as the creation of a European Citizen Science Academy and the establishment of a network of 28 ECS Ambassadors. It builds upon the previous EU-funded actions such as the eu-citizen.science project and Cos4Cloud and on the enormous achievements in citizen science over the past decade.
                                </p>
                                <ul>
                                    <li>Expand the reach of citizen science by strengthening the community</li>
                                    <li>Enhance digital skills for FAIR and open science communities</li>
                                    <li>Further develop the eu-citizen.science platform through co-design</li>
                                    <li>Develop the ECS academy with free training to increase the capacity to conduct citizen science</li>
                                    <li>Boost inclusion and diversity for mainstreaming citizen science</li>
                                    <li>Advocate for citizen science and working on policy impacts</li>
                                    <li>Investigate the impact of citizen science on research, society and economy</li>
                                </ul>
                            </div>
                        </div>
                        <div className="right">
                            <div className="image">
                                <img src={ecs} alt="ecs" style={{ marginTop: '45px' }} />
                            </div>
                            <div className="duration">
                                <div className="duration-title">
                                    Duration of the project
                                </div>
                                <div className="duration-text">
                                    1 August 2022- 31 July 2026
                                </div>
                            </div>
                            <div className="more-info">
                                <div className="more-info-title">
                                    More information
                                </div>
                                <div className="more-info-text">
                                    <a href="https://eu-citizen.science/" rel="noreferrer" target="_blank">https://eu-citizen.science/</a>
                                </div>
                                <div className="social">
                                    <a href="https://x.com/eucitsciproject" target="_blank" rel="noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-twitter-x" viewBox="0 0 16 16">
                                            <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {location.pathname === "/oss-platform/services/orri-community/slibing-projects-platforms/impetus" && (
                <div className="services-tabs-body-data services-tabs-body-data-slibing">
                    <div className="breadcrumb">
                        Orri Community / Sibling Projects & Platform / <span>Impetus</span>
                    </div>
                    <a className="back" href="/oss-platform/services/orri-community/slibing-projects-platforms">
                        <FaArrowLeft />Back to projects
                    </a>
                    <div className="slibing-container">
                        <div className="left">
                            <div className="slibing-title">
                                IMPETUS
                            </div>
                            <div className="slibing-summary">
                                <p>
                                    IMPETUS will support and give recognition to citizen science (CS) by enabling a wider range of citizen science initiatives (CSIs) to access innovative funding. We aim to bring CS closer to society and policymakers and to acknowledge its role in tackling the greatest challenges of our times. We will have a strong focus on enhancing its contributions to the Green Deal and the UN SDGs.
                                </p>
                                <p>
                                    IMPETUS supports Citizen Science Initiatives through:
                                </p>
                                <ul>
                                    <li>
                                        3 Open Calls, selecting the initiatives based on expected impacts, volunteer engagement, EDI, openness and quality data. We will offer 20k to kick-start 100 CSIs and 10k to sustain 25 CSIs addressing the pressing needs of European society
                                    </li>
                                    <li>
                                        Setting up an accelerator that will provide an integrated programme of support, training, mentoring, and resources. The accelerator will facilitate peer learning, enable CSIs to contribute to UN SDG and GD targets and forge connections with quadruple helix stakeholders.
                                    </li>
                                    <li>
                                        Launching the EU Prize for Citizen Science, awarded to CSIs for outstanding achievements, allowing them to continue and expand their work and showcase it to a broader audience. We will award 3 prize categories for 3 years: outstanding achievements, diversity and innovative grassroots projects. Each time, we will reach out to advisors to identify exceptional nominations and to the citizen panel to vote in the grassroots category.
                                    </li>
                                    <li>
                                        Convening a citizen panel that provides feedback on our work
                                    </li>
                                    <li>
                                        Shaping EU policy in and with CS, through horizon scanning, anticipatory policy and action research, informing policy briefs, webinars and workshops with key policy stakeholders. The aim is to foster more CS data to inform evidence-based policies and identify future directions in CS policy.
                                    </li>
                                    <li>
                                        Developing impact assessment tools and assessing the impact of CSIs and our own framework, especially on GD and SDG targets. Insights will be presented within the wider CS ecosystem and feed into recommendations for national and EU policies.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="right">
                            <div className="image">
                                <img src={impetus} alt="impetus" />
                            </div>
                            <div className="duration">
                                <div className="duration-title">
                                    Duration of the project
                                </div>
                                <div className="duration-text">
                                    1 July 2022 - 30 June 2026
                                </div>
                            </div>
                            <div className="more-info">
                                <div className="more-info-title">
                                    More information
                                </div>
                                <div className="more-info-text">
                                    <a href="https://impetus4cs.eu/" rel="noreferrer" target="_blank">https://impetus4cs.eu/</a>
                                </div>
                                <div className="social">
                                    <a href="https://www.linkedin.com/company/impetus4cs/" target="_blank" rel="noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 30 30">
                                            <path d="M9,25H4V10h5V25z M6.501,8C5.118,8,4,6.879,4,5.499S5.12,3,6.501,3C7.879,3,9,4.121,9,5.499C9,6.879,7.879,8,6.501,8z M27,25h-4.807v-7.3c0-1.741-0.033-3.98-2.499-3.98c-2.503,0-2.888,1.896-2.888,3.854V25H12V9.989h4.614v2.051h0.065 c0.642-1.18,2.211-2.424,4.551-2.424c4.87,0,5.77,3.109,5.77,7.151C27,16.767,27,25,27,25z"></path>
                                        </svg>
                                    </a>
                                    <a href="https://x.com/impetus4cs" target="_blank" rel="noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-twitter-x" viewBox="0 0 16 16">
                                            <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {location.pathname === "/oss-platform/services/orri-community/slibing-projects-platforms/irise" && (
                <div className="services-tabs-body-data services-tabs-body-data-slibing">
                    <div className="breadcrumb">
                        Orri Community / Sibling Projects & Platform / <span>iRISE</span>
                    </div>
                    <a className="back" href="/oss-platform/services/orri-community/slibing-projects-platforms">
                        <FaArrowLeft />Back to projects
                    </a>
                    <div className="slibing-container">
                        <div className="left">
                            <div className="slibing-title">
                                iRISE (Improving Reproducibility In SciencE)
                            </div>
                            <div className="slibing-summary">
                                <p>
                                    The iRISE (improving Reproducibility In SciencE) Project is a Horizon Europe initiative, to provide a richer and deeper understanding of drivers of poor reproducibility; and secondly to conduct a detailed evaluation – including primary research – of the effectiveness of interventions to increase reproducibility. It will present concrete solutions of tools and interventions that will help increase the quality, reliability and re-usability of scientific evidence. It focuses on areas of science where a reproducibility problem is already recognised (e.g., clinical trials, preclinical research, social sciences), providing theoretical and empirical evidence describing the effectiveness of specific interventions (the “toolbox”). iRISE will propose a framework for a robust, evidence-based road map for the development, assessment and implementation of interventions intended to improve reproducibility (the “framework”).
                                </p>
                                <p>
                                    Some of the objectives of iRISE entail:
                                </p>
                                <ul>
                                    <li>
                                        Develop working definitions and a general framework for diagnosing and addressing reproducibility problems, define costs, benefits and opportunities, and assess the utility of theoretical evidence in forecasting the success of interventions.
                                    </li>
                                    <li>
                                        Perform scoping and systematic reviews to identify and evaluate existing interventions to improve reproducibility.
                                    </li>
                                    <li>
                                        Explore the interface between reproducibility and research culture, and in particular considerations and mainstreaming of equity, diversity, and inclusion (EDI).
                                    </li>
                                    <li>
                                        Consult and engage key stakeholder groups in prioritising practices and practical tool development for adoption to increase reproducibility.
                                    </li>
                                    <li>
                                        Test efficacy and feasibility of specific interventions to increase reproducibility.
                                    </li>
                                    <li>
                                        Curate and integrate these different types of evidence to increase reproducibility for dissemination and implementation.
                                    </li>
                                    <li>
                                        Effectively project manage iRISE to achieve our objectives in a timely manner within budget.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="right">
                            <div className="image">
                                <img src={irise} alt="irise" />
                            </div>
                            <div className="duration">
                                <div className="duration-title">
                                    Duration of the project
                                </div>
                                <div className="duration-text">
                                    1 September 2023 - 31 August 2026
                                </div>
                            </div>
                            <div className="more-info">
                                <div className="more-info-title">
                                    More information
                                </div>
                                <div className="more-info-text">
                                    <a href="https://www.irise-project.eu/" rel="noreferrer" target="_blank">https://www.irise-project.eu/</a>
                                </div>
                                <div className="social">
                                    <a href="https://www.linkedin.com/company/irise-eu/" target="_blank" rel="noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 30 30">
                                            <path d="M9,25H4V10h5V25z M6.501,8C5.118,8,4,6.879,4,5.499S5.12,3,6.501,3C7.879,3,9,4.121,9,5.499C9,6.879,7.879,8,6.501,8z M27,25h-4.807v-7.3c0-1.741-0.033-3.98-2.499-3.98c-2.503,0-2.888,1.896-2.888,3.854V25H12V9.989h4.614v2.051h0.065 c0.642-1.18,2.211-2.424,4.551-2.424c4.87,0,5.77,3.109,5.77,7.151C27,16.767,27,25,27,25z"></path>
                                        </svg>
                                    </a>
                                    <a href="https://x.com/iRISE_EU" target="_blank" rel="noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-twitter-x" viewBox="0 0 16 16">
                                            <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {location.pathname === "/oss-platform/services/orri-community/slibing-projects-platforms/osiris" && (
                <div className="services-tabs-body-data services-tabs-body-data-slibing">
                    <div className="breadcrumb">
                        Orri Community / Sibling Projects & Platform / <span>Osiris</span>
                    </div>
                    <a className="back" href="/oss-platform/services/orri-community/slibing-projects-platforms">
                        <FaArrowLeft />Back to projects
                    </a>
                    <div className="slibing-container">
                        <div className="left">
                            <div className="slibing-title">
                                OSIRIS (Open Science to Increase Reproducibility in Science)
                            </div>
                            <div className="slibing-summary">
                                <p>
                                    The OSIRIS (Open Science to Increase Reproducibility in Science) project is dedicated to reforming the Research and Innovation (R&I) system, with the goal of significantly increasing the global acceptance, practice, and recognition of reproducibility in scientific research by 2026.
                                </p>
                                <p>
                                    Reproducibility, which allows for the confirmation or correction of study outcomes, is crucial for improving research quality, making results more reliable and implementable, and reducing overall research costs. However, despite its importance, only a small fraction of published studies are reproducible due to factors such as the pressure to publish, the need for constant innovation, and career assessments focused on quantity over quality.
                                </p>
                                <p>
                                    The main objectives of OSIRIS include creating a collaborative community to promote reproducibility, testing and developing solutions, and embedding reproducibility into the core strategy and design of research projects. Currently, the responsibility for ensuring reproducibility largely falls on individual researchers, with few incentives or support structures in place.
                                </p>
                                <p>
                                    OSIRIS aims to bring about a cultural and paradigm shift in the R&I system by addressing these challenges from both a top-down and bottom-up approach. The project will gather knowledge on the factors affecting reproducibility, test evidence-based solutions, and identify stakeholder-driven incentives for fostering reproducibility in research. By leveraging the expertise of a team with hands-on experience in Open Science (OS), reproducibility, and data sharing, along with the support of an engaged Advisory Board (AB), OSIRIS hopes to embed reproducibility as a fundamental aspect of research, thereby rebuilding trust in the scientific process.
                                </p>
                            </div>
                        </div>
                        <div className="right">
                            <div className="image">
                                <img src={osiris} alt="osiris" style={{ width: '96px', marginTop: '21px', marginLeft: '21px' }} />
                            </div>
                            <div className="duration">
                                <div className="duration-title">
                                    Duration of the project
                                </div>
                                <div className="duration-text">
                                    1 January 2023 - 31 December 2026
                                </div>
                            </div>
                            <div className="more-info">
                                <div className="more-info-title">
                                    More information
                                </div>
                                <div className="more-info-text">
                                    <a href="https://osiris4r.eu/" rel="noreferrer" target="_blank">https://osiris4r.eu/</a>
                                </div>
                                <div className="social">
                                    <a href="https://www.linkedin.com/company/osiris4r/" target="_blank" rel="noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 30 30">
                                            <path d="M9,25H4V10h5V25z M6.501,8C5.118,8,4,6.879,4,5.499S5.12,3,6.501,3C7.879,3,9,4.121,9,5.499C9,6.879,7.879,8,6.501,8z M27,25h-4.807v-7.3c0-1.741-0.033-3.98-2.499-3.98c-2.503,0-2.888,1.896-2.888,3.854V25H12V9.989h4.614v2.051h0.065 c0.642-1.18,2.211-2.424,4.551-2.424c4.87,0,5.77,3.109,5.77,7.151C27,16.767,27,25,27,25z"></path>
                                        </svg>
                                    </a>
                                    <a href="https://x.com/osiris4r" target="_blank" rel="noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-twitter-x" viewBox="0 0 16 16">
                                            <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {location.pathname === "/oss-platform/services/orri-community/slibing-projects-platforms/tier2" && (
                <div className="services-tabs-body-data services-tabs-body-data-slibing">
                    <div className="breadcrumb">
                        Orri Community / Sibling Projects & Platform / <span>Tier2</span>
                    </div>
                    <a className="back" href="/oss-platform/services/orri-community/slibing-projects-platforms">
                        <FaArrowLeft />Back to projects
                    </a>
                    <div className="slibing-container">
                        <div className="left">
                            <div className="slibing-title">
                                Tier2
                            </div>
                            <div className="slibing-summary">
                                <p>
                                    Through the collaboration of experts from 10 partner institutions from 7 European countries, TIER2 will work to tackle the reproducibility crisis different scientific domains. TIER2 aims to boost knowledge on reproducibility, create tools, engage communities, implement interventions and policy across different contexts to increase re-use and overall quality of research results. The project will centre epistemic diversity by selecting three broad research areas - social, life, and computer sciences, and two cross-disciplinary stakeholder groups - research publishers and funders to systematically investigate and improve reproducibility across contexts.
                                </p>
                                <p>
                                    Reproducibility is often claimed as a central principle of the scientific method. It refers to the possibility for the scientific community to obtain the same results as the originators of a specific finding. Recently, concerns about a “reproducibility crisis” have grown in a variety of disciplines, especially in behavioural and medical sciences. This has been exacerbated by key problems such as a lack of transparency in reporting, data, and analysis, lack of replication studies, publication bias towards reporting of positive results, and a growing awareness of questionable research practices.
                                </p>
                                <p>
                                    Poor levels of reproducibility are seen as a serious threat to scientific self-correction, efficiency of research processes, and societal trust in research results. There is a need to address issues in reproducibility in order to reduce inefficiencies, avoid repetition, maximise return on investment, prevent mistakes, and speed innovation to bring trust, integrity and efficiency to the European Research Area (ERA) and the global Research and Innovation (R&I) system in general.
                                </p>
                            </div>
                        </div>
                        <div className="right">
                            <div className="image">
                                <img src={tier2} alt="tier2" style={{ width: '100px', borderRadius: '30px', marginLeft: '20px', marginTop: '20px' }} />
                            </div>
                            <div className="duration">
                                <div className="duration-title">
                                    Duration of the project
                                </div>
                                <div className="duration-text">
                                    1 January 2023 - 31 December 2025
                                </div>
                            </div>
                            <div className="more-info">
                                <div className="more-info-title">
                                    More information
                                </div>
                                <div className="more-info-text">
                                    <a href="https://tier2-project.eu/" rel="noreferrer" target="_blank">https://tier2-project.eu/</a>
                                </div>
                                <div className="social">
                                    <a href="https://www.linkedin.com/company/tier2-project/" target="_blank" rel="noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 30 30">
                                            <path d="M9,25H4V10h5V25z M6.501,8C5.118,8,4,6.879,4,5.499S5.12,3,6.501,3C7.879,3,9,4.121,9,5.499C9,6.879,7.879,8,6.501,8z M27,25h-4.807v-7.3c0-1.741-0.033-3.98-2.499-3.98c-2.503,0-2.888,1.896-2.888,3.854V25H12V9.989h4.614v2.051h0.065 c0.642-1.18,2.211-2.424,4.551-2.424c4.87,0,5.77,3.109,5.77,7.151C27,16.767,27,25,27,25z"></path>
                                        </svg>
                                    </a>
                                    <a href="https://x.com/TIER2Project" target="_blank" rel="noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-twitter-x" viewBox="0 0 16 16">
                                            <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {location.pathname === "/oss-platform/services/orri-community/slibing-projects-platforms/futureresilience" && (
                <div className="services-tabs-body-data services-tabs-body-data-slibing">
                    <div className="breadcrumb">
                        Orri Community / Sibling Projects & Platform / <span>FutureResilience</span>
                    </div>
                    <a className="back" href="/oss-platform/services/orri-community/slibing-projects-platforms">
                        <FaArrowLeft />Back to projects
                    </a>
                    <div className="slibing-container">
                        <div className="left">
                            <div className="slibing-title">
                                FutureResilience
                            </div>
                            <div className="slibing-summary">
                                <p>
                                    The FUTURESILIENCE project seeks to strengthen Europe's economic and social resilience by enhancing the ability to respond quickly and effectively to future crises. It will achieve this by facilitating the application of policy-relevant research and innovation (R&I) findings through 10 pilot cases known as "Future Resilience Labs." These labs will serve as co-creation environments where multiple stakeholders, including policymakers, researchers, and community representatives, will come together to discuss and test evidence-based strategies tailored to their specific local and regional contexts. The project will use Foresight and participative methodologies to ensure these strategies meet the local needs.
                                </p>
                                <p>
                                    The need for resilience has grown as crises, such as pandemics, natural disasters, energy issues, and climate change, have become more frequent over the past two decades. These events have highlighted inequalities in preparedness across public, private, and civil society sectors, as well as at all institutional levels. FUTURESILIENCE aims to address these disparities by leveraging R&I to provide stakeholders with more flexible and responsive capacities to cope with crises, adapt to changing circumstances, and recover effectively. This process will inform policy development and decision-making, strengthening preparedness and resilience for future events.
                                </p>
                                <p>
                                    The project will focus on four main objectives:
                                </p>
                                <ul>
                                    <li>Mapping existing policy-relevant European R&I findings that have high potential to inform policymaking for economic and social resilience and addressing societal challenges.</li>
                                    <li>Defining methodologies and guidelines for testing the applicability of these R&I findings to inform policies at national, regional, and local levels.</li>
                                    <li>Implementing 10 "Future Resilience Labs" where stakeholders will experiment with tailored strategies in co-creation settings.</li>
                                    <li>-	Developing a Knowledge Base of successfully tested research findings and a Toolbox of methods to assist policy actors in testing and applying relevant research findings for resilience-building.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="right">
                            <div className="image">
                                <img src={futureresilience} alt="future" />
                            </div>
                            <div className="duration">
                                <div className="duration-title">
                                    Duration of the project
                                </div>
                                <div className="duration-text">
                                    1 January 2023 - 31 December 2025
                                </div>
                            </div>
                            <div className="more-info">
                                <div className="more-info-title">
                                    More information
                                </div>
                                <div className="more-info-text">
                                    <a href="https://futuresilience.eu/" rel="noreferrer" target="_blank">https://futuresilience.eu/</a>
                                </div>
                                <div className="social">
                                    <a href="https://www.linkedin.com/company/futuresilience/" target="_blank" rel="noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 30 30">
                                            <path d="M9,25H4V10h5V25z M6.501,8C5.118,8,4,6.879,4,5.499S5.12,3,6.501,3C7.879,3,9,4.121,9,5.499C9,6.879,7.879,8,6.501,8z M27,25h-4.807v-7.3c0-1.741-0.033-3.98-2.499-3.98c-2.503,0-2.888,1.896-2.888,3.854V25H12V9.989h4.614v2.051h0.065 c0.642-1.18,2.211-2.424,4.551-2.424c4.87,0,5.77,3.109,5.77,7.151C27,16.767,27,25,27,25z"></path>
                                        </svg>
                                    </a>
                                    <a href="https://x.com/futuresilience" target="_blank" rel="noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-twitter-x" viewBox="0 0 16 16">
                                            <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {location.pathname === "/oss-platform/services/orri-community/slibing-projects-platforms/coalesce" && (
                <div className="services-tabs-body-data services-tabs-body-data-slibing">
                    <div className="breadcrumb">
                        Orri Community / Sibling Projects & Platform / <span>Coalesce</span>
                    </div>
                    <a className="back" href="/oss-platform/services/orri-community/slibing-projects-platforms">
                        <FaArrowLeft />Back to projects
                    </a>
                    <div className="slibing-container">
                        <div className="left">
                            <div className="slibing-title">
                                COALESCE (Coordinated Opportunities for Advanced Leadership and Engagement in Science Communication in Europe)
                            </div>
                            <div className="slibing-summary">
                                <p>
                                    COALESCE is a four-year, EU-funded initiative designed to advance excellence in science communication, public engagement, and co-creation practices across Europe. The project, funded by the European Commission, aims to create a self-sustaining European Competence Centre for Science Communication, alongside a Science Communication Academy that will operate within the Centre.
                                </p>
                                <p>
                                    The project's primary goal is to bridge the gap between scientific understanding and European societies, addressing challenges like public distrust in science and ineffective policy responses during scientific crises. COALESCE will build on existing high-quality science communication methods, while fostering public engagement with science and enhancing co-creation practices between scientists and the public.
                                </p>
                                <p>
                                    By working closely with relevant stakeholders, COALESCE will translate knowledge from projects funded under the EU’s Science with and for Society (SwafS-19) programme, as well as other EU and national science-communication initiatives, into practical resources and tools. These outputs will be designed to support high-quality, evidence-based, and interdisciplinary science communication, further enhancing the connection between science, society, and policymaking.
                                </p>
                            </div>
                        </div>
                        <div className="right">
                            <div className="image">
                                <img src={coalesce} alt="coalesce" />
                            </div>
                            <div className="duration">
                                <div className="duration-title">
                                    Duration of the project
                                </div>
                                <div className="duration-text">
                                    1 April 2023 - 31 March 2027
                                </div>
                            </div>
                            <div className="more-info">
                                <div className="more-info-title">
                                    More information
                                </div>
                                <div className="more-info-text">
                                    <a href="https://coalesceproject.eu/" rel="noreferrer" target="_blank">https://coalesceproject.eu/</a>
                                </div>
                                <div className="social">
                                    <a href="https://www.linkedin.com/company/coalesce-scicomm/ " target="_blank" rel="noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 30 30">
                                            <path d="M9,25H4V10h5V25z M6.501,8C5.118,8,4,6.879,4,5.499S5.12,3,6.501,3C7.879,3,9,4.121,9,5.499C9,6.879,7.879,8,6.501,8z M27,25h-4.807v-7.3c0-1.741-0.033-3.98-2.499-3.98c-2.503,0-2.888,1.896-2.888,3.854V25H12V9.989h4.614v2.051h0.065 c0.642-1.18,2.211-2.424,4.551-2.424c4.87,0,5.77,3.109,5.77,7.151C27,16.767,27,25,27,25z"></path>
                                        </svg>
                                    </a>
                                    <a href="https://x.com/scicommEU" target="_blank" rel="noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-twitter-x" viewBox="0 0 16 16">
                                            <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}