import "./ContactUsAlternative.css";

export default function ContactUsAlternative() {
    return (
        <div id="contact-us-alt">
            <div className="content">
                <div className="contact-us-alt-content">
                    <div className="contact-us-alt-1">
                        <div id="contact-us-alt-img"></div>
                        <div className="contact-us-alt-title">
                            Do you have a question?
                        </div>
                    </div>
                    <div className="contact-us-alt-2">
                        <div id="contact-us-alt-body">
                            If you have any queries about the grants (boosters and incubators),
                            please contact the REINFORCING team
                        </div>
                        <div className="email" id="email-1">
                            <a href="mailto:grants@reinforcing.eu">
                                grants@reinforcing.eu
                            </a>
                        </div>
                        <div id="contact-us-alt-body">
                            If you have any queries about the Open Call for Evaluators,
                            please contact REINFORCING team
                        </div>
                        <div className="email" id="email-2">
                            <a href="mailto:evaluators@reinforcing.eu">
                                evaluators@reinforcing.eu
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}