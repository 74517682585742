import SocialAndNewsletter from "../../components/SocialAndNewsletter";
import logoHeader from "../../images/contact_logo.svg";
import Newsletter from "../../components/Newsletter";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

import "./ContactPage.css";

export default function ContactPage() {
    return (
        <div className="page-container contact">
            <Header className="header-contact" />
            <div id="title">
                <div className="content">
                    <div className="title-subtitle">&nbsp;</div>
                    <div className="title-title">Contact</div>
                    <div className="title-logo">
                        <img src={logoHeader} alt="reinforcing" />
                    </div>
                </div>
            </div>
            <div className="contact-body">
                <div className="content">
                    <div id="breadcrumb">
                        <div className="content">
                            Home / Contact
                        </div>
                    </div>
                    <div className="email-addresses">
                        <div className="introduction">
                            <div id="title">
                                <h1>Do you have a question?</h1>
                            </div>
                            <div id="subtitle">
                                <h4>
                                    Should you have any queries or require further information, please
                                    do not hesitate to contact us using the following email addresses
                                </h4>
                            </div>
                        </div>
                        <div className="cards-container">
                            <svg id="circle-1" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 60 20" fill="none">
                                <circle cx="20" cy="20" r="20" fill="#003A41"/>
                            </svg>
                            <svg id="circle-2" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 60 20" fill="none">
                                <circle cx="20" cy="20" r="20" fill="#017581"/>
                            </svg>
                            <svg id="circle-3" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 60 20" fill="none">
                                <circle cx="20" cy="20" r="20" fill="#08B0C0"/>
                            </svg>
                            <div className="card" id="card-1">
                                <div id="title">
                                    <h2>Get support</h2>
                                </div>
                                <div id="body">
                                    <h5>
                                        For general inquiries about the project, general
                                        information, and assistance contact us at
                                    </h5>
                                </div>
                                <div id="button-1">
                                    <a href="mailto:info@reinforcing.eu">
                                        info@reinforcing.eu
                                    </a>
                                </div>
                            </div>
                            <div className="card" id="card-2">
                                <div id="title">
                                    <h2>Grants inquiries</h2>
                                </div>
                                <div id="body">
                                    <h5>
                                        For questions regarding Grants (Boosters & Incubators)
                                        and other calls-related matters contact us at
                                    </h5>
                                </div>
                                <div id="button-2">
                                    <a href="mailto:grants@reinforcing.eu">
                                        grants@reinforcing.eu
                                    </a>
                                </div>
                            </div>
                            <div className="card" id="card-3">
                                <div id="title">
                                    <h2>Evaluators inquiries</h2>
                                </div>
                                <div id="body">
                                    <h5>
                                        For information about the Open Call for Evaluators
                                        and related information contact us at
                                    </h5>
                                </div>
                                <div id="button-3">
                                    <a href="mailto:evaluators@reinforcing.eu">
                                        evaluators@reinforcing.eu
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="footer">
                            <h3>
                                We look forward to hearing from you and collaborating
                                on our shared journey towards fostering Open and
                                Responsible Research and Innovation in Europe.
                            </h3>
                        </div>
                        <div className="social-media">
                            <h5 id="social-text">follow us on</h5>
                            <a href="https://twitter.com/REINFORCING_eu" target="_blank" rel="noreferrer" className="btn-social-media">
                                <span className="social-media-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-twitter-x" viewBox="0 0 16 16">
                                        <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z" />
                                    </svg>
                                </span>
                            </a>
                            <a href="https://www.linkedin.com/company/102311771/admin/feed/posts/?feedType=following" target="_blank" rel="noreferrer" className="btn-social-media">
                                <span className="social-media-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
                                        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401m-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4" />
                                    </svg>
                                </span>
                            </a>
                            <a href="https://www.youtube.com/@REINFORCINGeu" target="_blank" rel="noreferrer" className="btn-social-media">
                                <span className="social-media-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-youtube" viewBox="0 0 16 16">
                                        <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z"/>
                                    </svg>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <Newsletter />
            <SocialAndNewsletter />
            <Footer />
        </div>
    );
}