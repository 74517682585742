import { gql, useQuery } from "@apollo/client";
import PostCard from "../components/PostCard";
import React from "react";

import "./PostsList.css";

export default function PostsList({ categoryNumber }) {

    const BATCH_SIZE = 12;
    let GET_ALL_POSTS;
    let category = "";

    if (categoryNumber === 0) {
        GET_ALL_POSTS = gql`query getPosts($first: Int!, $after: String) {
            posts(first: $first, after: $after) {
                pageInfo {
                    hasNextPage
                    endCursor
                }
                nodes {
                    databaseId
                    title
                    date
                    slug
                    featuredImage {
                        node {
                            altText
                            sourceUrl
                        }
                    }
                }
            }
        }`;
    } else {
        if (categoryNumber === 1) {
            category = "events";
        } else if (categoryNumber === 2) {
            category = "open-calls";
        } else if (categoryNumber === 3) {
            category = "evaluators";
        } else if (categoryNumber === 4) {
            category = "orri";
        }
        GET_ALL_POSTS = gql`query getPosts($first: Int!, $after: String, $category: String!) {
            posts(first: $first, after: $after, where: { categoryName: $category }) {
                pageInfo {
                    hasNextPage
                    endCursor
                }
                nodes {
                    databaseId
                    title
                    date
                    slug
                    featuredImage {
                        node {
                            altText
                            sourceUrl
                        }
                    }
                }
            }
        }`;
    }

    const { loading, error, data, fetchMore } = useQuery(GET_ALL_POSTS, {
        variables: { first: BATCH_SIZE, after: null, category: category },
        notifyOnNetworkStatusChange: true
    });

    if (error) {
        return <p>Sorry, an error happened. Reload Please</p>;
    }

    if (!data && loading) {
        return <p>Loading...</p>;
    }

    const postsFound = Boolean(data?.posts.nodes.length);
    if (!postsFound) {
        return <p className="mt-4">No matching posts found.</p>;
    }

    const haveMorePosts = Boolean(data?.posts?.pageInfo?.hasNextPage);
    const postsRows = [];
    for (let i = 0; i < data.posts.nodes.length; i += 3) {
        const row = data.posts.nodes.slice(i, i + 3);
        postsRows.push(row);
    }

    return (
        <div className="posts-list">
            {postsRows.map((row, rowIndex) => (
                <div className={rowIndex === postsRows.length - 1 && row.length < 3 ? "posts-last-row" : "posts-row"} key={rowIndex}>
                    {row.map((post, index) => (
                        <PostCard key={index} post={post} />
                    ))}
                </div>
            ))}
            {haveMorePosts ? (
                <form
                    method="post"
                    onSubmit={(event) => {
                        event.preventDefault();
                        fetchMore({
                            variables: {
                                after: data.posts.pageInfo.endCursor
                            },
                            updateQuery: (prev, { fetchMoreResult }) => {
                                if (!fetchMoreResult) return prev;
                                return {
                                    posts: {
                                        nodes: [...prev.posts.nodes, ...fetchMoreResult.posts.nodes],
                                        pageInfo: fetchMoreResult.posts.pageInfo
                                    }
                                };
                            }
                        });
                    }}
                >
                    <button type="submit" disabled={loading}>
                        {loading ? "Loading..." : "Load more"}
                    </button>
                </form>
            ) : ''}
        </div>
    );
}
