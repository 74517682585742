import Navigation from "../../components/Navigation";
import Footer from "../../components/Footer";
import logo from '../../logo.png';

export default function AboutMethodologyPage() {
    return (
        <div className="page-container">
            <header>
                <div className="content">
                    <div id="logo">
                        <a href="/"><img src={logo} alt="reinforcing" /></a>
                    </div>
                    <div id="menu">
                        <Navigation />
                    </div>
                </div>
            </header>
            <div id="banner">
                <div className="content">
                    <h1>Methodology</h1>
                </div>
            </div>
            <div id="content">
                <div className="content project-resources">
                    <p className="mt-4">In progress</p>
                </div>
            </div>
            <Footer />
        </div>
    );
}