import logoHeader from "../../images/current-calls/current_calls_bg_3.svg";
import ContactUsCurrentCalls from "../../components/ContactUsCurrentCalls";
import SocialAndNewsletter from "../../components/SocialAndNewsletter";
import node_circle from '../../images/about/node_circle.svg';
import submission from "../../images/grants/submission.svg";
import Newsletter from "../../components/Newsletter";
import scope from "../../images/grants/scope.svg";
import forms from "../../images/grants/forms.svg";
import faqs from "../../images/grants/faqs.svg";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

import "./CurrentCallsPage.css";

export default function CurrentCallsPage() {
    return (
        <div className="page-container grants">
            <Header className="header-grants-oc" />
            <div id="title">
                <div className="content">
                    <div className="title-subtitle">Open Calls</div>
                    <div className="title-title">Current call</div>
                </div>
            </div>
            <div id="breadcrumb">
                <div className="content">
                    Home / Open Calls / Current Calls
                </div>
            </div>
            <div id="content">
                <div id="header">
                    <div className="content">
                        <div className="schema">
                            <div className="body">
                                <div className="title">
                                    3rd REINFORCING Open Call
                                </div>
                                <div className="subtitle">
                                    Incubators call
                                </div>
                                <div className="text">
                                    Applicants can submit their proposal by <b>26th September 2024</b> at <b>17:00</b> (Brussels time).<br></br>
                                    Successful proposals will receive up to <b>60.000 euros</b> to implement <b>12-months projects</b>.
                                </div>
                                <div className="text">
                                    On <b>September 2</b> (15:00-16:30 CET), REINFORCING will host a <b>2nd webinar</b> to present call’s details and guide applicants through the application process. <b>To join 
                                    the webinar register <a target="_blank" rel="noreferrer" href="https://us06web.zoom.us/meeting/register/tZAqfuqurDwpG9BMNmhjO_R9tGjWzFGL0ePv#/registration">here</a></b>. Find 
                                    the 1st info session <b>recording <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=VndesneguFw">here</a></b>. Feel free to add your organisation's
                                    details on our <b><a target="_blank" rel="noreferrer" href="https://stickydot.padlet.org/SD/submission-request/pmywv9lNYL1OXGA8">online board</a></b>, which is used to look
                                    for partners for this call.
                                </div>
                            </div>
                            <div className="logo">
                                <div className="background">
                                    <img src={logoHeader} alt="reinforcing" />
                                </div>
                                <div className="logo-title">
                                    The 3rd REINFORCING Open Call (Incubators Call) <br /> <span className="highlighted">is now closed!</span>
                                </div>
                                {/* <a href="/open-calls/open-calls/incubators">
                                    Apply
                                </a> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div id="current-calls-objectives">
                    <div className="content">
                        <div className="title">
                            Topic of the call
                        </div>
                        <div className="text">
                            <b>
                                The goal of this Incubators Call is to support organisations
                                and territories active in contributing to European Missions
                                by engaging relevant stakeholders, including citizens, in R&I
                                process and/or policies connected to one of the EU Missions.
                                Consortia applying for this open call should be able to
                                demonstrate that they are active in one of the EU Missions.
                            </b>
                        </div>
                        <div className="text2">
                            The Full Call Text document and the EU Funding & Tenders Portal provide
                            more details on the specific challenges and the expected outcomes of the Call.
                        </div>
                        <a className="full-call-text" href="/3rd REINFORCING Open Call.pdf" target="_blank">
                            Full call text
                        </a>
                        <div className="objective-button">
                            <a target="_blank" href="https://ec.europa.eu/info/funding-tenders/opportunities/portal/screen/opportunities/competitive-calls-cs/7921?order=DESC&pageNumber=1&pageSize=50&sortBy=startDate&isExactMatch=true&type=8&status=31094502" id="guide-link">
                                EU Funding & Tenders Portal →
                            </a>
                        </div>
                    </div>
                </div>
                <div className="application-banner">
                    <div className="content">
                        <div className="open-calls-content">
                            <div className="open-calls-1">
                                <div id="application-1">
                                    <h1 id="application-section-2">
                                        Specific call requirements
                                    </h1>
                                    <h5 id="application-section-3">
                                        Projects must be run in the context of <b>one of the EU Missions</b> and
                                        the project actions must contribute to the achievement of the EU Missions.
                                    </h5>
                                    <img id="application-img" src={node_circle} alt="node_circle" />
                                </div>
                            </div>
                            <div className="open-calls-2">
                                <div id="application-2">
                                    <h1 id="application-section-4">
                                        Who could apply
                                    </h1>
                                    <h5 id="application-section-5">
                                        <ul>
                                            <li>
                                                The consortium should include <b>at least 2 entities</b> based in EU countries
                                                (Member State or associated country), including their outermost regions or
                                                associated countries eligible to receive HEU grants, provided that the applicants
                                                are not covered by the Council sanctions in force. The main applicant <b>should
                                                have little or no experience in multi-stakeholder engagement in EU Missions.</b>
                                            </li>
                                            <li>
                                                Applicants have to be <b>legal entities</b>, already
                                                established at the moment of the submission.
                                            </li>
                                            <li>
                                                Applicants are required to <b>declare their financial stability</b>.
                                            </li>
                                            <li>
                                                Applicants should <b>not have any potential conflicts of interest</b> with
                                                the partners of the REINFORCING consortium, including their affiliated entities.
                                            </li>
                                        </ul>
                                    </h5>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
                <div id="more-info">
                    <div className="title">
                        How to apply
                    </div>
                    <div className="content">
                        <div className="text">
                            Applicants are required to complete an online form and a <b>template</b> to be submitted in
                            the <b><a target="_blank" href="/open-calls/open-calls/incubators" id="submission-link">submission page</a></b>. Proposals
                            should not be longer <b>than 10 pages</b> and applications exceeding the limits or including
                            further sections will not considered.
                        </div>
                    </div>
                    <div className="elements-mobile">
                        <div className="content">
                            <div className="element">
                                <img src={scope} alt="Scope of the call" />
                                <div className="title">
                                    Scope of the call
                                </div>
                                <div className="text">
                                    Find the call text
                                </div>
                                <a href="/3rd REINFORCING Open Call.pdf" download>
                                    <span id="information-buttons">
                                        More info
                                    </span>
                                </a>
                            </div>
                            <div className="element">
                                <img src={submission} alt="Submission Link" />
                                <div className="title" id="submission-title">
                                    Submission page
                                </div>
                                <div className="text">
                                    Access to the submission platform to apply
                                </div>
                                {/* <a href="/open-calls/open-calls/incubators ">
                                    <span id="information-buttons">
                                        Apply
                                    </span>
                                </a> */}
                            </div>
                            <div className="element">
                                <img src={forms} alt="Applicants Forms" />
                                <div className="title">
                                    Guidelines for Applicants
                                </div>
                                <div className="text">
                                    Download the Guide for Applicants
                                </div>
                                <a href="/Guidelines for applicants.pdf" download>
                                    <span id="information-buttons">
                                        Download
                                    </span>
                                </a>
                            </div>
                            <div className="element">
                                <img src={faqs} alt="FAQs" />
                                <div className="title">
                                    FAQs
                                </div>
                                <div className="text">
                                    Find the answers to frequently asked questions
                                </div>
                                <a href="/FAQs_updated_09.2024.pdf" download>
                                    <span id="information-buttons">
                                        View faqs
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="call-calendar">
                    <div className="content">
                        <div className="title">
                            call calendar
                        </div>
                        <div className="dates">
                            <div className="title">
                                Important dates for the application process are described in the following table:
                            </div>
                            <ul id="dates-table">
                                <li>
                                    <div className="date">
                                        1st July 2024
                                    </div>
                                    <div className="text">
                                        Official launch of the call
                                    </div>
                                </li>
                                <li>
                                    <div className="date">
                                        26th September 2024
                                    </div>
                                    <div className="text">
                                        Deadline for proposal submission - 17:00 (Brussels time)
                                    </div>
                                </li>
                                <li>
                                    <div className="date">
                                        October/November 2024
                                    </div>
                                    <div className="text">
                                        Evaluation process
                                    </div>
                                </li>
                                <li>
                                    <div className="date">
                                        November/December 2024
                                    </div>
                                    <div className="text">
                                        Communication to projects selected for funding
                                    </div>
                                </li>
                                <li>
                                    <div className="date">
                                        1st January 2025
                                    </div>
                                    <div className="text">
                                        Start of the funded projects
                                    </div>
                                </li>
                                <li>
                                    <div className="date">
                                        31st December 2025
                                    </div>
                                    <div className="text">
                                        End of the funded projects
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <ContactUsCurrentCalls />
            <Newsletter />
            <SocialAndNewsletter />
            <Footer />
        </div>
    )
}