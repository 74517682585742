import SocialAndNewsletter from "../../components/SocialAndNewsletter";
import fraunhofer from "../../images/consortium/Fraunhofer.svg";
import italbiotec from "../../images/consortium/Italbiotec.svg";
import stickydot from "../../images/consortium/Stickydot.svg";
import tecnalia from "../../images/consortium/Tecnalia.svg";
import basetti from "../../images/consortium/Basetti.svg";
import inmark from "../../images/consortium/Inmark.svg";
import rwth from "../../images/consortium/rwth.svg";
import ContactUs from "../../components/ContactUs";
import ait from "../../images/consortium/AIT.svg";
import vtt from "../../images/consortium/VTT.svg";
import ebn from "../../images/consortium/ebn.svg";
import ftn from "../../images/consortium/FTN.svg";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

import "./AboutConsortiumPage.css";

export default function AboutConsortiumPage() {
    return (
        <div className="page-container">
            <Header className="header-consortium" />
            <div id="title">
                <div className="content">
                    <div className="title-subtitle">About</div>
                    <div className="title-title">Consortium</div>
                </div>
            </div>
            <div className="breadcrumb-about" id="breadcrumb">
                <div className="content">
                    Home / About / Consortium
                </div>
                <div className="text">
                    <div className="content" id="consortium-intro">
                        A European wide consortium with 11 European frontrunners
                        in the field of Open and Responsible R&amp;I combining
                        research and implementation expertise in Open and
                        Responsible R&amp;I:
                    </div>
                </div>
            </div>
            <div id="partners">
                <div className="partner">
                    <div className="header">
                        <img src={basetti} alt="basetti" />
                        <div className="title">
                            Fondazione Giannino Bassetti (FGB)
                        </div>
                    </div>
                    <div className="body">
                        <p>
                            Fondazione Giannino Bassetti, a non-profit Civil Society Organization established in 1994, is a
                            pioneer in promoting responsibility in innovation within technoscience, entrepreneurship, and
                            governance. FGB spearheads Responsible Research and Innovation (RRI), fostering collaboration
                            among innovation drivers, research sites, industry, civil society, and institutions in open
                            innovation and quadruple-helix contexts. With a robust track record, FGB excels in coordinating
                            and conducting key tasks in EU-funded RRI projects, including SMART-map (H2020), TRANSFORM (H2020),
                            MOSAIC (H2020), ENLIGHT (H2020) and CO-VALUE (Horizon Europe).
                        </p>
                    </div>
                    <div className="footer">
                        <p>
                            <a href="https://www.fondazionebassetti.org/" target="_blank" rel="noreferrer">visit website</a>
                        </p>
                    </div>
                </div>
                <div className="partner-separator"></div>
                <div className="partner">
                    <div className="header">
                        <img src={ait} alt="AIT" />
                        <div className="title">
                            Austrian Institute of Technology (AIT)
                        </div>
                    </div>
                    <div className="body">
                        <p>
                            AIT provides research and technological development related technologies in the fields of
                            Innovation Policy, Energy, Low-Emission Transport, Health & Bioresources, Digital Safety &
                            Security, Vision, Automation & Control and Technology Experience.
                        </p>
                        <p>
                            As a national and international network node at the interface of science and industry AIT
                            enables innovation through its scientific-technological expertise, market experience, tight
                            customer relationships and high-quality research infrastructure. AIT is in charge of delivering
                            a one-stop source and a global network for REINFORCING (WP3).
                        </p>
                    </div>
                    <div className="footer">
                        <p>
                            <a href="https://www.ait.ac.at/" target="_blank" rel="noreferrer">visit website</a>
                        </p>
                    </div>
                </div>
                <div className="partner-separator"></div>
                <div className="partner">
                    <div className="header">
                        <img src={vtt} alt="VTT" />
                        <div className="title">
                            Teknologian Tutkimuskeskus VTT OY
                        </div>
                    </div>
                    <div className="body">
                        <p>
                            VTT, a leading European research institution owned by the Finnish state, focuses on translating
                            research and technology into practical applications for commerce and society. Leveraging scientific
                            and technological expertise, VTT turns global challenges into opportunities for sustainable growth,
                            benefiting businesses and society. They play a key role in consolidating the evidence base for
                            REINFORCING (WP1), contributing to the project's comprehensive understanding and advancement.
                        </p>
                    </div>
                    <div className="footer">
                        <p>
                            <a href="https://www.vttresearch.com/en" target="_blank" rel="noreferrer">visit website</a>
                        </p>
                    </div>
                </div>
                <div className="partner-separator"></div>
                <div className="partner">
                    <div className="header">
                        <div id="header-sp">
                            <img src={fraunhofer} alt="Fraunhofer" />
                        </div>
                        <div className="title">
                            Fraunhofer Gesellschaft Zur Forderung Der Angewandten Forschung
                        </div>
                    </div>
                    <div className="body">
                        <p>
                            The Fraunhofer Institute for Systems and Innovation Research ISI is one of Europe's leading
                            innovation research institutes. It conducts applied research in seven Competence Centers with
                            28 Business Units, aiming to serve society, politics, and industry. Their innovation research
                            expertise leverages technical, economic, and social sciences knowledge. The institute investigates
                            various aspects of innovation generation, including scientific, economic, ecological, social,
                            organizational, legal, and political conditions. Their scientifically grounded analysis and
                            forecasting methods aid decision-makers in industry, academia, and politics, fostering a conducive
                            environment for innovations. Fraunhofer ISI assess impacts and share recommendations of the REINFORING
                            project (WP5).
                        </p>
                    </div>
                    <div className="footer">
                        <p>
                            <a href="https://www.isi.fraunhofer.de/en.html" target="_blank" rel="noreferrer">visit website</a>
                        </p>
                    </div>
                </div>
                <div className="partner-separator"></div>
                <div className="partner">
                    <div className="header">
                        <img src={tecnalia} alt="Tecnalia" />
                        <div className="title">
                            Fundacion Tecnalia Research & Innovation
                        </div>
                    </div>
                    <div className="body">
                        <p>
                            Tecnalia is the largest centre of applied research and technological development in Spain, and a
                            member of the Basque Research and Technology Alliance. It collaborates with companies and institutions
                            to improve their competitiveness, people's quality of life and achieve sustainable growth.Its main
                            scopes of action are: smart manufacturing, digital transformation, energy transition, sustainable
                            mobility, health and food, urban ecosystem and circular economy. This partner is the owner of promoting
                            mainstreaming ORRI across ERA through cascading grants (WP4).
                        </p>
                    </div>
                    <div className="footer">
                        <p>
                            <a href="https://www.tecnalia.com/" target="_blank" rel="noreferrer">visit website</a>
                        </p>
                    </div>
                </div>
                <div className="partner-separator"></div>
                <div className="partner">
                    <div className="header">
                        <img src={italbiotec} alt="Italbiotec" />
                        <div className="title">
                            Consorzio Italbiotec
                        </div>
                    </div>
                    <div className="body">
                        <p>
                            Consorzio Italbiotec is considered the leading Italian non-profit organization in the biotechnology
                            sector, bringing together 22 Italian universities and research institutions, the National Research
                            Council, 10 foundations, and over 70 private companies and start-ups. Italbiotec leads scientific
                            development and knowledge transfer between the research community and industry, shaping a cutting-edge
                            innovation ecosystem driven by biotechnology start-ups, companies and universities were cooperation
                            triggers growth and sustainable innovation.
                        </p>
                    </div>
                    <div className="footer">
                        <p>
                            <a href="https://www.italbiotec.it/" target="_blank" rel="noreferrer">visit website</a>
                        </p>
                    </div>
                </div>
                <div className="partner-separator"></div>
                <div className="partner">
                    <div className="header">
                        <img src={stickydot} alt="Stickydot" />
                        <div className="title">
                            Stickydot SRL
                        </div>
                    </div>
                    <div className="body">
                        <p>
                            Stickydot, an SME headquartered in Brussels, specializes in consultancy services for stakeholder
                            engagement in research and innovation. The team excels in developing methodologies for multi-stakeholder
                            involvement, facilitating and assessing dialogue, and offering capacity building and training. With core
                            expertise in these areas, Stickydot contributes significantly to enhancing research processes and
                            fostering impactful multi-stakeholder engagement, making it a key player in the consultancy field.
                        </p>
                    </div>
                    <div className="footer">
                        <p>
                            <a href="https://stickydot.eu/" target="_blank" rel="noreferrer">visit website</a>
                        </p>
                    </div>
                </div>
                <div className="partner-separator"></div>
                <div className="partner">
                    <div className="header">
                        <img src={inmark} alt="Inmark" />
                        <div className="title">
                            Grupo Inmark
                        </div>
                    </div>
                    <div className="body">
                        <p>
                            Headquartered in Madrid, Inmark, a management consulting firm, has a broad experience in R&I project
                            management and international cooperation throughout Europe and Latin America. With leadership in
                            communication and exploitation strategies, their team's extensive background includes over 50 EU-funded
                            R&I projects. In the RRI framework, Inmark promotes gender equality in STI cooperation through design
                            thinking and advances gender balance in decision-making. Within REINFORCING, this partner is in charge
                            of raising awareness and maximizing the projects exploitation strategy (WP6).
                        </p>
                    </div>
                    <div className="footer">
                        <p>
                            <a href="https://www.grupoinmark.com/" target="_blank" rel="noreferrer">visit website</a>
                        </p>
                    </div>
                </div>
                <div className="partner-separator"></div>
                <div className="partner">
                    <div className="header">
                        <img src={ebn} alt="Ebn" />
                        <div className="title">
                            European Business And Innovation Centre Network (EBN)
                        </div>
                    </div>
                    <div className="body">
                        <p>
                            EBN, a non-profit organization, supports a pan-European global community leveraging innovative business
                            as a catalyst for regional economic development. EBN's initiatives encompass EU|BIC certification, the
                            creation and dissemination of high-quality business support programs, the facilitation and initiation of
                            project collaborations, as well as global networking and advocacy for outstanding business support actors
                            such as the EU|BICs.
                        </p>
                    </div>
                    <div className="footer">
                        <p>
                            <a href="https://ebn.eu/" target="_blank" rel="noreferrer">visit website</a>
                        </p>
                    </div>
                </div>
                <div className="partner-separator"></div>
                <div className="partner">
                    <div className="header">
                        <img src={rwth} alt="RWTH" />
                        <div className="title">
                            Rheinisch-Westfaelische Technische Hochschule Aachen
                        </div>
                    </div>
                    <div className="body">
                        <p>
                            At RWTH Aachen University, a dynamic environment thrives on robust research networks and the insatiable
                            intellectual curiosity of its dedicated staff. This synergy propels the institution to tackle audacious
                            scientific inquiries, disseminate cutting-edge knowledge, and spearhead innovative discoveries with
                            far-reaching implications for global challenges. The university's commitment to pushing the boundaries
                            of knowledge not only contributes to academic excellence but also underscores its role in shaping a
                            transformative future.
                        </p>
                    </div>
                    <div className="footer">
                        <p>
                            <a href="https://www.rwth-aachen.de/" target="_blank" rel="noreferrer">visit website</a>
                        </p>
                    </div>
                </div>
                <div className="partner-separator"></div>
                <div className="partner">
                    <div className="header">
                        <img src={ftn} alt="ftn" />
                        <div className="title">
                            Univerzitet U Novom Sadu Fakultet Tehnickih Nauka
                        </div>
                    </div>
                    <div className="body">
                        <p>
                            Faculty of Technical Sciences based in the Republic of Serbia. consists of 13 departments, 10
                            administrative services and 31 research centers. With over 15.000 students and 1.200 employees,
                            it ranks among the largest and most developed faculties in the region. Very supportive entrepreneurial
                            environment, highly skilled employees, and transfer and commercialization of knowledge at the faculty
                            resulted in creation of more than 130 spin-off SMEs with annual turnover of over 80 million Euros.
                        </p>
                    </div>
                    <div className="footer">
                        <p>
                            <a href="http://ftn.uns.ac.rs/n1386094394/faculty-of-technical-sciences" target="_blank" rel="noreferrer">visit website</a>
                        </p>
                    </div>
                </div>
            </div>
            <ContactUs />
            <SocialAndNewsletter />
            <Footer />
        </div>
    );
}