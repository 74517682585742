import { NavLink } from 'react-router-dom';
// import { Link } from "react-router-dom";
import React from "react";

import "./PostPageContent.css";

const formatDate = (date) => new Date(date).toLocaleDateString();

export default function PostPageContent({ post }) {

    const { date, title, content, author, categories } = post;
    const haveCategories = Boolean(categories?.nodes?.length);

    return (
        <article>
            <h1 id="post-page-title">{title}</h1>
            <p id="post-page-author" className="post-meta">
                <span role="img" aria-label="writing hand">
                    ✍️
                </span>{" "}
                {author.node.name} on {formatDate(date)}
            </p>
            <div
                id="post-page-body"
                className="post-content"
                dangerouslySetInnerHTML={{ __html: content }}
            />
            {haveCategories ? (
                <div className="categories-list">
                    <h2 id="post-page-categorization">Categorized As</h2>
                    <ul>
                        {categories.nodes.map((category) => {
                            const { slug, name } = category;
                            return (
                                // <Link id="post-page-category" to={`/category/${slug}`} key={slug}>
                                //     <li key={slug}>{name}</li>
                                // </Link>
                                // <NavLink to="/news" id="post-page-category" key={slug}>{name}</NavLink>
                                <NavLink
                                    to={{
                                        pathname: "/news",
                                        state: { categoryNumber: 1 }
                                    }}
                                    key={slug}
                                    id="post-page-category"
                                >
                                    {name}
                                </NavLink>
                            );
                        })}
                    </ul>
                </div>
            ) : null}
        </article>
    );
}