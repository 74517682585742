import React, { useState, useEffect } from "react";
import { set, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import SocialAndNewsletter from "../../components/SocialAndNewsletter";
import logoTitle from "../../images/oss-platform/title_logo.svg";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import * as yup from "yup";
import { Form, Button } from 'react-bootstrap';

import "./OSSPlatformAddTrainingPage.css";

export default function OSSPlatformAddTrainingPage() {

    const apiUrl = process.env.REACT_APP_API_URL;
    const createTrainingDataSchema = yup.object({
        name: yup.string().required(),
        authors: yup.string().required(),
        description: yup.string().required(),
        format: yup.string().required(),
        file: yup.mixed().nullable().when("format", {
            is: "Presentation",
            then: () => yup.mixed().required()
        }),
        url: yup.string().when("format", {
            is: (val) => val === "Link" || val === "Video",
            then: () => yup.string().required().matches(
                /^(https?:\/\/)?(www\.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(\/[a-zA-Z0-9#]+\/?)*(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                'Enter correct url!'
            )
        }),
        categories: yup.array().of(yup.string()).required().min(1, 'At least one category must be selected')
    });

    const formats = [
        { id: "Video", name: 'Video' },
        { id: "Presentation", name: 'Presentation' },
        { id: "Link", name: 'Link' }
    ];

    const [selectedFormat, setSelectedFormat] = useState("Video");

    const [wordLimitReached200, setWordLimitReached200] = useState(false);
    const [categories, setCategories] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);

    const handleWordSummaryLimit = (e) => {
        const inputValue = e.target.value;
        const words = inputValue.trim().split(/\s+/);

        if (words.length > 200) {
            e.preventDefault();
            const limitedWords = words.slice(0, 200).join(' ');
            e.target.value = limitedWords;
            setWordLimitReached200(true);
        } else {
            setWordLimitReached200(false);
        }
    };

    const { handleSubmit, register, reset, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(createTrainingDataSchema)
    });

    const handleFileChange = event => {
        if (event.target.files[0]) {
            setSelectedFile(event.target.files[0]);
            setValue("file", event.target.files[0]);
        }
    };

    const handleRemoveFile = () => {
        setSelectedFile(null);
        setValue("file", null);
        document.getElementById('file').value = '';
    };

    useEffect(() => {
        setValue("file", null);
        setValue("categories", []);
        fetch(`${apiUrl}/api/trainings/categories`)
            .then(response => response.json())
            .then(data => {
                setCategories(data);
            })
            .catch(error => {
                console.error("Error fetching resource types:", error);
            });
    }, []);

    const onSubmit = async data => {
        const formData = new FormData();
        formData.append("name", data.name);
        formData.append("authors", data.authors);
        formData.append("description", data.description);
        formData.append("format", data.format);
        formData.append("url", data.url);
        formData.append('categories', data.categories ? data.categories.join(',') : '');
        if (selectedFile) {
            formData.append('file', selectedFile);
        }
        try {
            const response = await fetch(`${apiUrl}/api/trainings`, {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                NotificationManager.success('Your form has been successfully uploaded', 'Submitted!');

                reset({
                    name: '',
                    authors: '',
                    description: '',
                    url: '',
                    format: 'Video',
                    file: null,
                    categories: []
                });

                setSelectedFile(null);
            } else {
                NotificationManager.error('A problem occurred while submitting the form', 'Error!');
                console.error('Error:', await response.text());
            }
        } catch (error) {
            console.error('Request failed:', error);
        }
    };

    return (
        <div className="page-container oss-platform">
            <NotificationContainer />
            <Header className="header-oss-platform" />
            <div id="title">
                <div className="content">
                    <div className="box">
                        <div className="box-title">
                            <h1>welcome to the reinforcing platform</h1>
                        </div>
                        <div className="box-logo">
                            <img src={logoTitle} alt="reinforcing" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="introduction">
                <div className="content">
                    <div className="introduction-title">
                        <h3>Add training data</h3>
                    </div>
                    <Form noValidate onSubmit={handleSubmit(onSubmit)} id="createForm" style={{ marginBottom: 50 }}>
                        <div className="form-group mt-3">
                            <label className="form-label" htmlFor="name">Name</label>
                            <input type="text" {...register("name")} className={`form-control mt-1 ${errors.name ? 'is-invalid' : ''}`} id="name" />
                            {errors.name && (
                                <p className="error-message">{errors.name.message}</p>
                            )}
                        </div>
                        <div className="form-group mt-3">
                            <label className="form-label" htmlFor="authors">Authors / Project name</label>
                            <input type="text" {...register("authors")} className={`form-control mt-1 ${errors.authors ? 'is-invalid' : ''}`} id="authors" />
                            {errors.authors && (
                                <p className="error-message">{errors.authors.message}</p>
                            )}
                        </div>
                        <div className="form-group mt-3">
                            <label className="form-label" htmlFor="summary">Description</label>
                            <textarea {...register("description")} onInput={handleWordSummaryLimit} className={`form-control mt-1 ${errors.description ? 'is-invalid' : ''}`} id="description" />
                            {wordLimitReached200 && (
                                <div className="alert alert-warning d-flex align-items-center mt-2" role="alert">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                                        <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                    </svg>
                                    <div>
                                        You have reached the 200 word limit.
                                    </div>
                                </div>
                            )}
                            {errors.description && (
                                <p className="error-message">{errors.description.message}</p>
                            )}
                        </div>
                        <div className="form-group mt-3">
                            <label className="form-label" htmlFor="orri">Format</label>
                            <select className={`form-select mt-1 ${errors.format ? 'is-invalid' : ''}`} id="format" {...register("format")} onChange={e => setSelectedFormat(e.target.value)}>
                                {

                                    formats.map((item, index) => {
                                        return (
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        )
                                    })
                                }
                            </select>
                            {errors.orri && (
                                <p className="error-message">{errors.orri.message}</p>
                            )}
                        </div>
                        {selectedFormat === "Presentation" && (
                            <div className="form-group mt-3">
                                <label className="form-label" htmlFor="file">File</label>
                                <input
                                    type="file"
                                    accept=".pdf,.ppt"
                                    className={`form-control mt-1 ${errors.file ? 'is-invalid' : ''}`}
                                    id="file"
                                    {...register("file")}
                                    onChange={handleFileChange}
                                />
                                {selectedFile && (
                                    <div className="mt-2">
                                        <button
                                            type="button"
                                            className="btn btn-danger"
                                            onClick={handleRemoveFile}
                                        >
                                            Remove File
                                        </button>
                                    </div>
                                )}
                                {errors.file && (
                                    <p className="error-message">{errors.file.message}</p>
                                )}
                            </div>
                        )}
                        {(selectedFormat === "Link" || selectedFormat === "Video") && (
                            <div className="form-group mt-3">
                                <label className="form-label" htmlFor="url">{selectedFormat === "Link" ? "Url" : "Url of video"}</label>
                                <input type="text" {...register("url")} className={`form-control mt-1 ${errors.url ? 'is-invalid' : ''}`} id="url" />
                            </div>
                        )}
                        <div className="form-group mt-3">
                            <label className="form-label" htmlFor="orri">Categories (You can select several fields)</label>
                            {
                                categories.map((item, index) => {
                                    return (
                                        <div key={item.id} className="form-check">
                                            <input className="form-check-input" type="checkbox" value={item.id} id={item.name} {...register("categories")} />
                                            <label className="form-check-label" htmlFor={item.name}>
                                                {item.name}
                                            </label>
                                        </div>
                                    )
                                })
                            }
                            {errors.categories && (
                                <p className="error-message">{errors.categories.message}</p>
                            )}
                        </div>
                        <Button type="submit" variant="primary" className="btn btn-primary mt-5 register" form="createForm">
                            Submit
                        </Button>
                    </Form>
                </div>
            </div>
            <SocialAndNewsletter />
            <Footer />
        </div>
    )
}