import logoHeader from "../../images/current-calls/current_calls_bg_3.svg";
import ContactUsCurrentCalls from "../../components/ContactUsCurrentCalls";
import SocialAndNewsletter from "../../components/SocialAndNewsletter";
import node_circle from '../../images/about/node_circle.svg';
import submission from "../../images/grants/submission.svg";
import Newsletter from "../../components/Newsletter";
import scope from "../../images/grants/scope.svg";
import forms from "../../images/grants/forms.svg";
import faqs from "../../images/grants/faqs.svg";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

import "./Mar2024CallPage.css";

export default function Mar2024CallPage() {
    return (
        <div className="page-container grants">
            <Header className="header-grants-oc" />
            <div id="title">
                <div className="content">
                    <div className="title-subtitle">Closed Call</div>
                    <div className="title-title">March 2024 Call</div>
                </div>
            </div>
            <div id="breadcrumb">
                <div className="content">
                    Home / Open Calls / Closed Call / March 2024 Call
                </div>
            </div>
            <div id="content">
                <div id="header">
                    <div className="content">
                        <div className="schema">
                            <div className="body">
                                <div className="title">
                                    2nd REINFORCING Open Call
                                </div>
                                <div className="subtitle">
                                    Incubators call
                                </div>
                                <div className="text">
                                    Applicants could submit their proposal until <b>30th May 2024</b> at <b>17:00</b> (Brussels time).<br></br>
                                    Successful proposals will receive up to <b>60.000 euros</b> to implement <b>12-months projects</b>.
                                </div>
                                <div className="text">
                                    On April 18th REINFORCING hosted a webinar in which we disclosed the insights,
                                    and guided participants through the application process of our Second Open Call.
                                    You can find the <b>webinar recording</b> <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=eeAxHnBcWA0">here</a>.
                                </div>
                            </div>
                            <div className="logo">
                                <div className="background">
                                    <img src={logoHeader} alt="reinforcing" />
                                </div>
                                <div className="logo-title">
                                    The 2nd REINFORCING Open Call (Incubators Call) <br /> <span className="highlighted">is now closed!</span>
                                </div>
                                <a href="/open-calls/open-calls/incubators" className="disabled">
                                    Apply
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="current-calls-objectives">
                    <div className="content">
                        <div className="title">
                            Topic of the call
                        </div>
                        <div className="text">
                            The goal of this Incubators Call was to strengthen ORRI within
                            the Balkan area by engaging institutions committed to understanding
                            and implementing ORRI principles and practices. The activities
                            performed should have resulted result in an evident institutional
                            change toward ORRI by the end of the proposed project.
                        </div>
                        <div className="text2">
                            The Full Call Text document and the EU Funding & Tenders Portal provide more details on
                            the specific challenges and the expected outcomes of the Call.
                        </div>
                        <a className="full-call-text" href="/2nd REINFORCING Open Call.pdf" download>
                            Full call text
                        </a>
                        <div className="objective-button">
                            <a target="_blank" href="https://ec.europa.eu/info/funding-tenders/opportunities/portal/screen/opportunities/competitive-calls-cs/5842?order=DESC&pageNumber=1&pageSize=50&sortBy=startDate&type=8&startDate=1711494000000&deadlineDate=1717020000000" id="guide-link">
                                EU Funding & Tenders Portal →
                            </a>
                        </div>
                    </div>
                </div>
                <div className="application-banner">
                    <div className="content">
                        <div className="open-calls-content">
                            <div className="open-calls-1">
                                <div id="application-1">
                                    <h1 id="application-section-2">
                                        Specific call requirements
                                    </h1>
                                    <h5 id="application-section-3">
                                        The <b>main beneficiary</b> of each consortium – <b>the newcomer implementing ORRI actions</b> - must be established
                                        or operate in the <b>Balkans area</b>, which includes the following countries: Albania, Bosnia and
                                        Herzegovina, Bulgaria, Croatia, Kosovo, Montenegro, North Macedonia, Romania, Serbia, and Slovenia.
                                    </h5>
                                    <h5 id="application-section-3">
                                        Successful proposals will receive up to <b>60.000 euros</b> to implement 12-months projects.
                                    </h5>
                                    <img src={node_circle} alt="node_circle" />
                                </div>
                            </div>
                            <div className="open-calls-2">
                                <div id="application-2">
                                    <h1 id="application-section-4">
                                        Who could apply
                                    </h1>
                                    <h5 id="application-section-5">
                                        <ul>
                                            <li>
                                                <b>Small consortia</b> of partners (minimum two partners per consortium), in which the main beneficiary
                                                was a <b>newcomer to ORRI</b> and would like to experiment with ORRI approaches in a <b>12-month project</b>.
                                            </li>
                                            <li>
                                                Partners in the consortium had to be <b>established in an EU country (Member State or associated country, including Balkans)</b>,
                                                including their outermost regions or associated countries eligible to receive Horizon Europe grants,
                                                provided that the applicants were not covered by the Council sanctions in force.
                                            </li>
                                            <li>
                                                <b>Applicants had to be legal entities</b>, already established at the moment of the submission, such as, but not limited to,
                                                Civil Society Organizations (CSO), Non-Governmental Organizations (NGO), Higher Education Institutions, Research Centers
                                                and Small and Medium Enterprises (SME). Self-employed applicants could also apply if they were legal entities.</li>
                                            <li>
                                                Applicants were required to<b>declare their financial stability</b>.
                                            </li>
                                            <li>
                                                Applicants <b>should not have had any potential conflicts of interest with the REINFORCING consortium or any of its
                                                partners</b> (notably, they should not have had a legal relationship with any organization member of the
                                                REINFORCING consortium, including affiliated entities).
                                            </li>
                                        </ul>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="more-info">
                    <div className="title">
                        How to apply
                    </div>
                    <div className="content">
                        <div className="text">
                            Applicants were required to complete an online form and a <b>template</b> that was to be submitted in the <b>submission page</b>. 
                            Proposals should not have been longer <b>than 10 pages</b> and applications that exceeded the limits or included further sections were not considered.
                        </div>
                    </div>
                    <div className="elements-mobile">
                        <div className="content">
                            <div className="element">
                                <img src={scope} alt="Scope of the call" />
                                <div className="title">
                                    Scope of the call
                                </div>
                                <div className="text">
                                    <b>Find the call text</b>
                                </div>
                                <a href="/2nd REINFORCING Open Call.pdf" download>
                                    <span id="information-buttons">
                                        More info
                                    </span>
                                </a>
                            </div>
                            <div className="element">
                                <img src={submission} alt="Submission Link" />
                                <div className="title" id="submission-title">
                                    Submission page
                                </div>
                                <div className="text">
                                    Access to the submission platform to apply is now closed
                                </div>
                                <a href="/open-calls/open-calls/incubators " className="disabled">
                                    <span id="information-buttons">
                                        Apply
                                    </span>
                                </a>
                            </div>
                            <div className="element">
                                <img src={forms} alt="Applicants Forms" />
                                <div className="title">
                                    Guidelines for Applicants
                                </div>
                                <div className="text">
                                    Download the Guide for Applicants
                                </div>
                                <a href="/Guidelines for applicants.pdf" download>
                                    <span id="information-buttons">
                                        Download
                                    </span>
                                </a>
                            </div>
                            <div className="element">
                                <img src={faqs} alt="FAQs" />
                                <div className="title">
                                    FAQs
                                </div>
                                <div className="text">
                                    Find the answers to frequently asked questions
                                </div>
                                <a href="/FAQs.pdf" download>
                                    <span id="information-buttons">
                                        View faqs
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="call-calendar">
                    <div className="content">
                        <div className="title">
                            call calendar
                        </div>
                        <div className="dates">
                            <div className="title">
                                Important dates for the application process are described in the following table:
                            </div>
                            <ul id="dates-table">
                                <li>
                                    <div className="date">
                                        27th March 2024
                                    </div>
                                    <div className="text">
                                        Official launch of the call
                                    </div>
                                </li>
                                <li>
                                    <div className="date">
                                        30th May 2024
                                    </div>
                                    <div className="text">
                                        Deadline for proposal submission - 17:00 (Brussels time)
                                    </div>
                                </li>
                                <li>
                                    <div className="date">
                                        June 2024
                                    </div>
                                    <div className="text">
                                        Evaluation process
                                    </div>
                                </li>
                                <li>
                                    <div className="date">
                                        July/August 2024
                                    </div>
                                    <div className="text">
                                        Communication to projects selected for funding
                                    </div>
                                </li>
                                <li>
                                    <div className="date">
                                        1st September 2024
                                    </div>
                                    <div className="text">
                                        Start of the funded projects
                                    </div>
                                </li>
                                <li>
                                    <div className="date">
                                        31st August 2025
                                    </div>
                                    <div className="text">
                                        End of the funded projects
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <ContactUsCurrentCalls />
            <Newsletter />
            <SocialAndNewsletter />
            <Footer />
        </div>
    );
}