function sendEmail(data) {

    const apiUrl = process.env.REACT_APP_API_URL;

    const userEmail = {
        email: data.email
    };

    return fetch(`${apiUrl}/api/users/subscribe`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(userEmail)
    })
    .then(response => {
        console.log('A1: ' + response.response.data.detail)
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return 0;
    })
    .catch(error => {        
        console.error('There was an error!', error);
        return 1;
    });

}

export default sendEmail;