import SocialAndNewsletter from "../../components/SocialAndNewsletter";
import node_circle from '../../images/about/node_circle.svg';
import europeMap from "../../images/about/about_header.svg";
import ContactUs from "../../components/ContactUs";
import logosphere1 from '../../images/logo.svg';
import Footer from "../../components/Footer";
import Header from "../../components/Header";

import './AboutPage.css';

export default function AboutPage() {
    return (
        <div className="page-container aboutPage">
            <Header className="header-about" />
            <div id="title">
                <div className="content">
                    <div className="title-subtitle">About</div>
                    <div className="title-title">Reinforcing</div>
                    <div className="about-title-img">
                        <img src={europeMap} alt="europe map" />
                    </div>
                </div>
            </div>
            <div className="breadcrumb-about-page" id="breadcrumb-ap">
                <div className="content">
                    Home / About
                </div>
            </div>
            <div id="content-about">
                <div id="content-header">
                    <div className="content">
                        <div className="left">
                            <h2>
                                Why Open and Responsible Research and Innovation (ORRI)?
                            </h2>
                            <div className="content-introduction-body">
                                <p id="introduction-step-1">
                                    In the current situation, the green and digital transitions have become an
                                    urgency that can no longer wait. The twin transition, as they have come to be
                                    known, will bring enormous benefits but also undesired externalities.
                                </p>
                                <p id="introduction-step-2">
                                    Making the digital and green transitions fair and inclusive is key: Institutions
                                    need to shift to more open and trasparent R&amp;I practices
                                </p>
                                <p id="introduction-step-3">
                                    To succeed, institutions and territories need to shift to more open and transparent
                                    R&I practices in which all actors share responsibility to develop services and
                                    products that are acceptable, sustainable and bring social satisfaction. Open and
                                    Responsible R&amp;I promotes collaborative efforts and knowledge transfer to help
                                    identify such solutions more effectively. From sharing research outputs as early as
                                    possible to engaging citizens in co-creation processes and promoting responsible
                                    research and innovation, R&amp;I should be open to the whole society.
                                </p>
                            </div>
                        </div>
                        <div className="right">
                            <img src={node_circle} alt="node_circle" />
                        </div>
                    </div>
                </div>
                <div className="content-explanation">
                    <div className="content">
                        <div className="content-explanation-logo">
                            <img src={logosphere1} alt="sphere1" />
                        </div>
                        <h3 id="explanation-step">
                            Why REINFORCING?
                        </h3>
                        <p id="explanation-step-1">
                            Implementing Open and Responsible R&amp;I can seem a daunting task. However,
                            over the past decade, many resources and tools have been created, but an
                            effort to systematize, curate and create a single entry point was still
                            missing.
                        </p>
                        <p id="explanation-step-2">
                            <strong>REINFORCING is the much needed European central hub for Open and
                            Responsible Research and Innovation.</strong> The project’s platform is the
                            entry point to knowledge, training, mentoring and capacity building for
                            organizations interested in starting or continuing their journey to creating
                            an inclusive R&amp;I ecosystem. Besides gathering and curating knowledge,
                            the project brokers unique networking opportunities for organizations, 
                            institutions and territories interested in becoming part of the European
                            ecosystem that is transforming the R&amp;I system by bringing it closer to
                            society and societal needs.
                        </p>
                    </div>
                </div>
                <div className="content-support">
                    <div className="content">
                        <div className="content-support-body">
                            <p id="content-support-step-1">
                                To support this change, <strong>REINFORCING has ring-fenced funding for 72 grants -
                                between 20,000 and 60,000 euros</strong> - to invite institutions from around Europe
                                to develop projects aiming at implementing new modes of doing R&amp;I that
                                drive more just transitions.
                            </p>
                            <p id="content-support-step-2">
                                Combining participatory methods to gather input and needs from the ORRI
                                community at large and the development and curation of resources and tools,
                                REINFORCING supplies the European Research and Innovation ecosystem with the
                                much-needed 360° support mechanism to encourage change.
                            </p>
                        </div>
                    </div>
                </div>
                <div id="objectives">
                    <div className="objectives-content">
                        <div className="title">
                            To succeed in its objectives REINFORCING will:
                        </div>
                        <div className="objective left">
                            <div className="image image1"></div>
                            <div className="number">
                                1
                            </div>
                            <div className="text">
                                <strong>Establish a two-way dialogue</strong> with the community to understand its needs and source knowledge and expertise
                            </div>
                        </div>
                        <div className="objective right">
                            <div className="text">
                                <strong>Make knowledge more accessible</strong> and keep it up-to-date
                            </div>
                            <div className="number">
                                2
                            </div>
                            <div className="image image2"></div>
                        </div>
                        <div className="objective left" id="left-3">
                            <div className="image image3" id="left-3-image"></div>
                            <div className="number" id="left-3-number">
                                3
                            </div>
                            <div className="text" id="left-3-text">
                                <strong>Establish a central point of contact</strong> between organizations looking to network, collaborate and learn from one another
                            </div>
                        </div>
                        <div className="objective right">
                            <div className="text">
                                <strong>Build capacity</strong> in European territories to conduct excellent R&I
                            </div>
                            <div className="number" id="right-4-number">
                                4
                            </div>
                            <div className="image image4"></div>
                        </div>
                        <div className="objective left" id="left-3">
                            <div className="image image5"></div>
                            <div className="number" id="left-5-number">
                                5
                            </div>
                            <div className="text" id="left-5-text">
                                <strong>Empower EU institutions and territories</strong> to make tangible progress in the implementation of this new R&I paradigm
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ContactUs />
            <SocialAndNewsletter />
            <Footer />
        </div>
    );
}