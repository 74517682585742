import Navigation from "../components/Navigation";
import logo from '../logo3.svg';
import './Header.css';

export default function Header(props) {
    return (
        <header className={`${ props.className }`}>
            <div className="content">
                <div id="logo">
                    <a href="/"><img src={logo} alt="reinforcing" /></a>
                </div>
                <div id="menu">
                    <Navigation />
                </div>
            </div>
        </header>
    );
}