import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import SocialAndNewsletter from "../../components/SocialAndNewsletter";
import logoTitle from "../../images/oss-platform/title_logo.svg";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import * as yup from "yup";
import { Form, Button } from 'react-bootstrap';

import "./OSSPlatformAddRriPage.css";

export default function OSSPlatformAddRriPage() {

    const apiUrl = process.env.REACT_APP_API_URL;
    const createRriToolsDataSchema = yup.object({
        authors: yup.string().required(),
        title: yup.string().required(),
        summary: yup.string().required(),
        yearOfPublication: yup.number().required(),
        url: yup.string().matches(
            /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            'Enter correct url!'
        )
        .required('Please enter website'),
    });

    const [keywords, setKeywords] = useState([]);

    const { handleSubmit, register, reset, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(createRriToolsDataSchema)
    });

    const onSubmit = async data => {
        const formData = new FormData();
        formData.append("authors", data.authors);
        formData.append("title", data.title);
        formData.append("summary", data.summary);
    };

    return (
        <div className="page-container oss-platform">
            <Header className="header-oss-platform" />
            <div id="title">
                <div className="content">
                    <div className="box">
                        <div className="box-title">
                            <h1>welcome to the reinforcing platform</h1>
                        </div>
                        <div className="box-logo">
                            <img src={logoTitle} alt="reinforcing" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="introduction">
                <div className="content">
                    <div className="introduction-title">
                        <h3>Add RRI tools data</h3>
                    </div>
                    <Form noValidate onSubmit={handleSubmit(onSubmit)} id="createForm" style={{marginBottom: 50}}>
                        <div className="form-group mt-3">
                            <label className="form-label" htmlFor="authors">Authors</label>
                            <input type="text" {...register("authors")} className={`form-control mt-1 ${errors.authors ? 'is-invalid' : ''}`} id="authors" />
                        </div>
                        <div className="form-group mt-3">
                            <label className="form-label" htmlFor="title">Title</label>
                            <input type="text" {...register("title")} className={`form-control mt-1 ${errors.title ? 'is-invalid' : ''}`} id="titl" />
                        </div>
                        <div className="form-group mt-3">
                            <label className="form-label" htmlFor="summary">Summary</label>
                            <textarea {...register("summary")} className={`form-control mt-1 ${errors.summary ? 'is-invalid' : ''}`} id="summary" />
                        </div>
                        <div className="form-group mt-3">
                            <label className="form-label" htmlFor="email">Year of publication</label>
                            <input type="number" min={1} {...register("yearOfPublication")} className={`form-control mt-1 ${errors.yearOfPublication ? 'is-invalid' : ''}`} id="yearOfPublication" />
                        </div>
                        <div className="form-group mt-3">
                            <label className="form-label" htmlFor="url">Url</label>
                            <input type="text" {...register("url")} className={`form-control mt-1 ${errors.url ? 'is-invalid' : ''}`} id="url" />
                        </div>
                        <div className="form-group mt-3">
                            <label className="form-label" htmlFor="orri">Keywords</label>
                            {
                                keywords.map((item, index) => {
                                    return (
                                        <div key={index} className="form-check">
                                            <input className="form-check-input" type="checkbox" value={item.id} id={item.text} {...register("orri")} />
                                            <label className="form-check-label" htmlFor={item.text}>
                                                {item.text}
                                            </label>
                                        </div>
                                    )
                                })
                            }
                            {errors.orri && (
                                <p className="error-message">{errors.orri.message}</p>
                            )}
                            <input type="text" {...register("orriOptional1")} placeholder="Free ORRI keyword" className={`form-control optional mt-3 ${errors.orriOptional1 ? 'is-invalid' : ''}`} id="orriOptional1" />
                            <input type="text" {...register("orriOptional2")} placeholder="Free ORRI keyword" className={`form-control optional mt-1 ${errors.orriOptional2 ? 'is-invalid' : ''}`} id="orriOptional2" />
                        </div>
                        <Button type="submit" variant="primary" className="btn btn-primary mt-5 register" form="createForm">
                            Submit
                        </Button>
                    </Form>
                </div>
            </div>
            <SocialAndNewsletter />
            <Footer />
        </div>
    )
}