import ContactUsAlternative from "../../components/ContactUsAlternative";
import SocialAndNewsletter from "../../components/SocialAndNewsletter";
import incubatorIcon from '../../images/grants/incubator_icon.svg';
import boosterIcon from '../../images/grants/booster_icon.svg';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import React, { useEffect } from 'react';

import "./OpenCallsPage.css";

export default function OpenCallsPage() {

    useEffect(() => {
        const buttons = document.querySelectorAll('.orri-map-grants-cards-btn');
        const handleClick = function (event) {
            event.preventDefault();
            const targetSelector = event.getAttribute('data-target');
            const targetElement = document.querySelector(targetSelector);
            if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'smooth' });
            }
        };
        buttons.forEach(button => {
            button.addEventListener('click', handleClick);
        });
        return () => {
            buttons.forEach(button => {
                button.removeEventListener('click', handleClick);
            });
        };
    }, []);

    return (
        <div className="page-container grants opencalls">
            <div className="open-calls-image-big">
                <div className="open-calls-img">
                    <div className="open-calls-back">
                        <h1 id="open-calls-body">
                            3RD REINFORCING Open Call (Incubators Call)
                        </h1>
                        <a href="/open-calls/current-calls/" id="open-calls-link">
                            Learn more →
                        </a>
                    </div>
                </div>
            </div>
            <Header className="header-grants-oc" />
            <div id="title">
                <div className="content">
                    <div className="title-subtitle">Open Calls</div>
                    <div className="title-title">Grants</div>
                </div>
            </div>
            <div id="breadcrumb">
                <div className="content">
                    Home / Open Calls / Grants
                </div>
            </div>
            <div id="open-calls-banner">
                <div className="content">
                    <div className="open-calls-content">
                        <div className="open-calls-text">
                            <div className="open-calls-header">
                                <h2 id="open-calls-title">
                                    REINFORCING provides financial support to European organizations in
                                    the form of grants to enable and support their journey towards open
                                    and responsible research and innovation.
                                </h2>
                            </div>
                            <div className="open-calls-subheader">
                                <h3 id="open-calls-subtitle">
                                    All REINFORCING calls adhere to EU principles of transparency, equal
                                    treatment, handling conflict of interest, and confidentiality, ensuring
                                    an objective and straightforward selection procedure, which is conducted
                                    thanks to the support of external reviewers selected through the Open
                                    Call for External Evaluators.
                                </h3>
                            </div>
                            <div className="open-calls-header-button">
                                <a href="/Guidelines for applicants.pdf" download className="open-calls-header-btn">
                                    <span id="open-calls-header-btn-text">
                                        Learn more about the REINFORCING application process
                                    </span>
                                </a>
                                <br /><br />
                                <a href="/FAQs_updated_09.2024.pdf" download className="open-calls-header-btn">
                                    <span id="open-calls-header-btn-text">
                                        Download applicants faq's
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="orri-map-grants">
                        <div id="orri-map-grants-title">
                            Based on the level of ORRI maturity of the applicants,
                            REINFORCING delivers two kinds of grants:
                        </div>
                        <div className="orri-map-grants-schema">
                            <div className="orri-map-grants-cards">
                                <div className="orri-map-grants-container">
                                    <div className="omg-card">
                                        <div className="omg-card-content">
                                            <div className="card-icon">
                                                <img src={boosterIcon} alt="booster icon" />
                                            </div>
                                            <div className="omg-card-header">
                                                <div className="orri-map-grants-cards-title">
                                                    boosters
                                                </div>
                                                <div className="calendar-date-card">
                                                    4 calls
                                                </div>
                                            </div>
                                            <div className="card-title orri-map-grants-cards-subtitle">
                                                Small Grants
                                            </div>
                                            <p className="card-description orri-map-grants-cards-text">
                                                Small grants of <strong>20.000 €</strong> for beneficiaries
                                                already <strong>experienced in ORRI</strong> to implement in
                                                a <strong>8-month</strong> project.
                                            </p>
                                            <button className="orri-map-grants-cards-btn" data-target="#target-1">
                                                more info
                                            </button>
                                        </div>
                                    </div>
                                    <div className="omg-card" id="omg-card-2">
                                        <div className="omg-card-content">
                                            <div className="card-icon">
                                                <img src={incubatorIcon} alt="incubator icon" />
                                            </div>
                                            <div className="omg-card-header">
                                                <div className="orri-map-grants-cards-title">
                                                    incubators
                                                </div>
                                                <div className="calendar-date-card">
                                                    3 calls
                                                </div>
                                            </div>
                                            <div className="card-title orri-map-grants-cards-subtitle">
                                                Large Grants
                                            </div>
                                            <p className="card-description orri-map-grants-cards-text">
                                                Large grants of <strong>60.000 € for newcomers</strong> to
                                                ORRI to implement in a <strong>12-month</strong> project.
                                            </p>
                                            <button className="orri-map-grants-cards-btn" id="orri-map-grants-cards-btn-2" data-target="#target-2">
                                                more info
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="boosters">
                    <div className="content">
                        <div className="open-calls-content-2">
                            <div className="open-calls-1" id="target-1">
                                <div id="boosters-1">
                                    <h2 id="boosters-section-1">
                                        ORRI Boosters
                                    </h2>
                                    <h3 id="boosters-section-2">
                                        Small grants, also called ORRI Boosters, are awarded to 14
                                        applicants (at least one beneficiary) already experienced in
                                        ORRI, who would like to strengthen and institutionalize their
                                        approach and actions in the field.
                                    </h3>
                                    <h4 id="boosters-section-3">
                                        Each ORRI Booster call will support organizations to implement
                                        an <strong>8-month</strong> project. <strong>The maximum amount
                                            granted for each project is 20.000 €.</strong>
                                        <br /><br />
                                        REINFORCING will launch 4 Boosters calls.
                                    </h4>
                                    <h3 id="boosters-section-6" style={{ marginTop: 0 }}>
                                        Past calls:
                                    </h3>
                                    <h5 id="boosters-section-5">
                                        <div className="calendar-date-sp">
                                            November 2023
                                        </div>
                                        <div className="arrow-btn-date-2">
                                            <a href="/closed-calls/november-2023-call" id="arrow-btn-link" type="button">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" className="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z" />
                                                </svg>
                                            </a>
                                        </div>
                                    </h5>
                                    <h3 id="boosters-section-7-2">
                                        Upcoming calls:
                                    </h3>
                                    <h5 id="boosters-section-5">
                                        <div id="boosters-section-dates-colums">
                                            <div className="calendar-date">
                                                November 2024
                                            </div>
                                            <div className="calendar-date">
                                                March 2025
                                            </div>
                                            <div className="calendar-date">
                                                November 2025
                                            </div>
                                        </div>
                                    </h5>
                                </div>
                            </div>
                            <div className="open-calls-2">
                                <div id="boosters-2">
                                    <h4 id="boosters-section-3">
                                        Each Booster call will address a specific topic, which will be
                                        identified by the Consortium in dialogue with the ORRI community.
                                    </h4>
                                    <h3 id="boosters-section-4">
                                        Boosters’ grantees are required to:
                                    </h3>
                                    <h4 id="boosters-section-3">
                                        <ul>
                                            <li>
                                                Deliver at least one ORRI tool to be included in the
                                                REINFORCING One-Stop Source;
                                            </li>
                                            <li>
                                                Attend the mid-term review;
                                            </li>
                                            <li>
                                                Attend a final webinar at the end of their project;
                                            </li>
                                            <li>
                                                Contribute to the online communication and update of
                                                the REINFORCING project channels (e.g., website, social
                                                media, etc.) with grantees' achievements and information;
                                            </li>
                                            <li>
                                                Engage in peer learning activities and contribute to the
                                                ORRI community advancement.
                                            </li>
                                        </ul>
                                    </h4>
                                    <h4 id="boosters-section-3">
                                        Applicants must submit their application via the project website.
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="incubators" id="target-2">
                    <div className="content">
                        <div className="open-calls-content-2">
                            <div className="open-calls-1" id="target-2">
                                <div id="boosters-1">
                                    <h2 id="boosters-section-1">
                                        ORRI Incubators
                                    </h2>
                                    <h3 id="boosters-section-2">
                                        Large grants, also called ORRI Incubators, are awarded to consortia
                                        of beneficiaries (at least two beneficiaries per consortium) that
                                        are new to ORRI and would like to embark on the ORRI approach.
                                    </h3>
                                    <h4 id="boosters-section-3">
                                        Each ORRI Incubator call will support 8 consortia implementing
                                        a <strong>12-month</strong> project. <strong>The maximum amount
                                            granted for each project is 60.000 €. </strong> REINFORCING will
                                        launch 3 Incubator calls.
                                    </h4>
                                    <h3 id="boosters-section-6" style={{ marginTop: 0 }}>
                                        Past calls:
                                    </h3>
                                    <h5 id="boosters-section-5">
                                        <div className="calendar-date-sp">
                                            March 2024
                                        </div>
                                        <div className="arrow-btn-date">
                                            <a href="/closed-calls/march-2024-call" id="arrow-btn-link" type="button">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" className="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z" />
                                                </svg>
                                            </a>
                                        </div>
                                        <br /><br />
                                        <div className="calendar-date-sp">
                                            July 2024
                                        </div>
                                        <div className="arrow-btn-date-4">
                                            <a href="/open-calls/current-calls" id="arrow-btn-link" type="button">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" className="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z" />
                                                </svg>
                                            </a>
                                        </div>
                                    </h5>
                                    <h3 id="boosters-section-7-2">
                                        Upcoming calls:
                                    </h3>
                                    <h5 id="boosters-section-5">
                                        <div className="calendar-date">
                                            July 2025
                                        </div>
                                        <br /><br />
                                    </h5>
                                    <h4 className="boosters-section-3-class" id="boosters-section-3-2">
                                        Grantees will also benefit from REINFORCING support and services
                                        throughout the implementation of their activities, including training
                                        modules on ORRI principles and practices. Grantees will take part in
                                        peer-learning activities to showcase the results and impact of their
                                        actions and take part in online REINFORCING webinars.
                                    </h4>
                                </div>
                            </div>
                            <div className="open-calls-2" id="open-calls-2-id">
                                <div id="boosters-2-2">
                                    <h4 id="boosters-section-3">
                                        Incubator grantees will also receive mentoring support at the end of
                                        their journey to foster long-term ORRI sustainability and
                                        institutionalization.
                                    </h4>
                                    <h3 id="boosters-section-4">
                                        Incubators grantees are required to:
                                    </h3>
                                    <h4 id="boosters-section-3">
                                        <ul>
                                            <li>
                                                Deliver at least one ORRI tool to be included in the
                                                REINFORCING One-Stop Source;
                                            </li>
                                            <li>
                                                Attend preliminary training activities at the beginning
                                                of their activities to familiarize them with ORRI principles.
                                                Training will last 1.5 days and will be delivered on-site in
                                                Brussels. Costs related to training (personnel and travel)
                                                can be funded by the grant (thus, included in the budget
                                                overview);
                                            </li>
                                            <li>
                                                Attend the mid-term review;
                                            </li>
                                            <li>
                                                Attend a final webinar at the end of their project;
                                            </li>
                                            <li>
                                                Contribute to the online communication and update of the
                                                REINFORCING project channels (e.g., website, social media, etc.)
                                                with grantees' achievements and information.
                                            </li>
                                        </ul>
                                    </h4>
                                    <h4 className="boosters-section-3-class-2" id="boosters-section-3">
                                        Applicants must submit their application via the project website.
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="calendar-banner">
                <div className="content">
                    <div className="calendar-introduction">
                        <h3 id="calendar-introduction-1">
                            7 calls will be launched throughout the 50 months of the project:
                            4 ORRI Boosters calls and 3 ORRI Incubators calls.
                        </h3>
                        <h4 id="calendar-introduction-2">
                            Each call revolves around specific targets and objectives, which
                            are defined by REINFORCING partners in dialogue with the ORRI community.
                        </h4>
                        <h2 id="calendar-introduction-3">
                            Boosters (up to 20.000 €) and Incubators (up to 60.000 €) are distributed
                            during the lifetime of the project, according to the following scheme:
                        </h2>
                    </div>
                    <div className="calendar-schema">
                        <div className="line"></div>
                        <div className="column">
                            <div className="column-title">
                                Boosters
                            </div>
                            <div className="column-subtitle">
                                NOV 2023
                            </div>
                            <div className="column-node">
                                Closed
                            </div>
                            <div className="arrow-btn-date-3">
                                <a href="/closed-calls/november-2023-call" id="arrow-btn-link" type="button">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" className="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z" />
                                    </svg>
                                </a>
                            </div>
                        </div>
                        <div className="column">
                            <div className="column-title">
                                Incubators
                            </div>
                            <div className="column-subtitle">
                                MAR 2024
                            </div>
                            <div className="column-graph">
                                <div className="column-node">
                                    Closed
                                </div>
                            </div>
                            <div className="arrow-btn-date-3">
                                <a href="/closed-calls/march-2024-call" id="arrow-btn-link" type="button">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" className="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z" />
                                    </svg>
                                </a>
                            </div>
                        </div>
                        <div className="column">
                            <div className="column-title">
                                Incubators
                            </div>
                            <div className="column-subtitle">
                                JUL 2024
                            </div>
                            <div className="column-graph">
                                <div className="column-node">
                                    Closed
                                </div>
                            </div>
                            <div className="arrow-btn-date-3">
                                <a href="/open-calls/current-calls" id="arrow-btn-link" type="button">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" className="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z" />
                                    </svg>
                                </a>
                            </div>
                        </div>
                        <div className="column">
                            <div className="column-title">
                                Boosters
                            </div>
                            <div className="column-subtitle">
                                NOV 2024
                            </div>
                            <div className="column-graph">
                                <div className="column-node">
                                    Soon
                                </div>
                            </div>
                        </div>
                        <div className="column">
                            <div className="column-title">
                                Boosters
                            </div>
                            <div className="column-subtitle">
                                MAR 2025
                            </div>
                            <div className="column-graph">
                                <div className="column-node">
                                    Soon
                                </div>
                            </div>
                        </div>
                        <div className="column">
                            <div className="column-title">
                                Incubators
                            </div>
                            <div className="column-subtitle">
                                JUL 2025
                            </div>
                            <div className="column-graph">
                                <div className="column-node">
                                    Soon
                                </div>
                            </div>
                        </div>
                        <div className="column">
                            <div className="column-title">
                                Boosters
                            </div>
                            <div className="column-subtitle">
                                NOV 2025
                            </div>
                            <div className="column-graph">
                                <div className="column-node">
                                    Soon
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="evaluation-banner">
                <div className="content">
                    <div className="evaluation-introduction">
                        <h2 id="evaluation-introduction-1">
                            Evaluation process:
                        </h2>
                        <h4 id="evaluation-introduction-2">
                            Grant applications submitted in the framework of REINFORCING
                            are assessed through a three-stage evaluation process:
                        </h4>
                    </div>
                    <div className="evaluation-schema">
                        <div className="evaluation-cards">
                            <div className="evaluation-container">
                                <div className="ev-card">
                                    <div className="ev-card-content">
                                        <div className="card-title evaluation-cards-title">01.</div>
                                        <p className="evaluation-cards-text">
                                            An eligibility check
                                        </p>
                                    </div>
                                </div>
                                <div className="ev-card">
                                    <div className="ev-card-content">
                                        <div className="card-title evaluation-cards-title">02.</div>
                                        <p className="evaluation-cards-text">
                                            A preliminary assessment run by the Consortium
                                        </p>
                                    </div>
                                </div>
                                <div className="ev-card">
                                    <div className="ev-card-content">
                                        <div className="card-title evaluation-cards-title">03.</div>
                                        <p className="evaluation-cards-text">
                                            A final evaluation by a committee composed of external
                                            panelists experts in the topic of the call.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <a href="/open-calls/calls-evaluation" className="open-calls-header-btn">
                            <span id="open-calls-header-btn-text">
                                Learn more about the call for evaluators
                            </span>
                        </a>
                        </div>
                        
                    </div>
                </div>
            </div>
            <ContactUsAlternative />
            <SocialAndNewsletter />
            <Footer />
        </div>
    )
}