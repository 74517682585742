import "./ContactUs.css";

export default function ContactUs() {
    return (
        <div id="contact-us">
            <div className="sub-footer-question-logo"></div>
            <div className="title">
                Do you have a question about this call?
            </div>
            <div className="body">
                Contact us!
            </div>
            <div className="email">
                <a href="mailto:grants@reinforcing.eu">
                    grants@reinforcing.eu
                </a>
            </div>
        </div>
    );
}