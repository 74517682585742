import { NotificationContainer, NotificationManager } from 'react-notifications';
import SocialAndNewsletter from "../../components/SocialAndNewsletter";
import { yupResolver } from '@hookform/resolvers/yup';
import Newsletter from "../../components/Newsletter";
import React, { useState, useEffect } from "react";
import ContactUs from "../../components/ContactUs";
import { Form, Button } from 'react-bootstrap';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import "react-notifications/lib/notifications.css";
import "./CallForEvaluatorsApplyPage.css";
import "./CurrentCallsPage.css";

export default function CallForEvaluatorsPage() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const createBoosterSchema = yup.object({
        name: yup.string().required(),
        surname: yup.string().required(),
        email: yup.string().required().email(),
        affiliation: yup.string().required(),
        orri: yup.array().of(yup.string()).required().min(1),
        brief: yup.string().required()
            .test(
                "len",
                "can be empty or with string and not more than 300",
                (val) => {
                    if (val === undefined) {
                        return true;
                    }
                    return countWords(val) <= 300;
                }
            ),
        cv: yup.string().required(),
        termsOfService: yup.boolean().oneOf([true], 'You have to accept')
    });

    const countWords = (s) => {
        s = s.replace(/(^\s*)|(\s*$)/gi, "");//exclude  start and end white-space
        s = s.replace(/[ ]{2,}/gi, " ");//2 or more space to 1
        s = s.replace(/\n /, "\n"); // exclude newline with a start spacing
        return s.split(' ').filter(function (str) { return str !== ""; }).length;
        //return s.split(' ').filter(String).length; - this can also be used
    }

    const [file, setFile] = useState(null);
    const [orriWords, setOrriWords] = useState([]);

    const handleFileChange = (e) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
            setValue('cv', e.target.files[0]);
        }
    };

    const getOrriWords = () => {
        fetch(`${apiUrl}/api/grants/orri-words`)
            .then(response => response.json())
            .then(data => {
                setOrriWords(data);
            });
    }

    const { handleSubmit, register, reset, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(createBoosterSchema)
    });

    const onSubmit = async data => {
        const formData = new FormData();
        formData.append("name", data.name);
        formData.append("surname", data.surname);
        formData.append("email", data.email);
        formData.append("affiliation", data.affiliation);
        formData.append("orri", data.orri);
        formData.append("brief", data.brief);
        formData.append('file', file);
        fetch(`${apiUrl}/api/evaluators/upload`, {
            method: 'POST',
            body: formData
        }).then(() => {
            reset({
                name: '',
                surname: '',
                email: '',
                affiliation: '',
                orri: [],
                brief: '',
                termsOfService: false
            });
            setFile(null);
            document.getElementById('cv').value = null;
            setValue('cv', null);
            NotificationManager.success('Information sucessfully sent', 'Success!');
        }).catch(error => {
            NotificationManager.error(error, 'Error!');
        });
    }

    useEffect(() => {
        getOrriWords()
    }, []);

    return (
        <div className="page-container grants">
            <NotificationContainer />
            <Header className="header-grants" />
            <div id="title">
                <div className="content">
                    <div className="title-subtitle">Grants</div>
                    <div className="title-title">Evaluators</div>
                </div>
            </div>
            <div id="breadcrumb">
                <div className="content">
                    Home / Grants / Call for Evaluators / Apply
                </div>
            </div>
            <div id="booster">
                <div className="content">
                    <div className="downloads">
                        <a href="/Open call for external evaluators.pdf" download className="download">Download guidelines</a>
                    </div>
                    <Form noValidate onSubmit={handleSubmit(onSubmit)} id="createForm">
                        <div className="form-group mt-3">
                            <label className="form-label" htmlFor="name">Name</label>
                            <input type="text" {...register("name")} className={`form-control mt-1 ${errors.name ? 'is-invalid' : ''}`} id="name" />
                        </div>
                        <div className="form-group mt-3">
                            <label className="form-label" htmlFor="surname">Surname</label>
                            <input type="text" {...register("surname")} className={`form-control mt-1 ${errors.surname ? 'is-invalid' : ''}`} id="surname" />
                        </div>
                        <div className="form-group mt-3">
                            <label className="form-label" htmlFor="email">Email</label>
                            <input type="email" {...register("email")} className={`form-control mt-1 ${errors.email ? 'is-invalid' : ''}`} id="email" />
                        </div>
                        <div className="form-group mt-3">
                            <label className="form-label" htmlFor="affiliation">Affiliation</label>
                            <input type="text" min={1} {...register("affiliation")} className={`form-control mt-1 ${errors.affiliation ? 'is-invalid' : ''}`} id="affiliation" />
                        </div>
                        <div className="form-group mt-3">
                            <label className="form-label" htmlFor="orri">ORRI words</label>
                            {
                                orriWords.map((item, index) => {
                                    return (
                                        <div key={index} className="form-check">
                                            <input className="form-check-input" type="checkbox" value={item.id} id={item.text} {...register("orri")} />
                                            <label className="form-check-label" htmlFor={item.text}>
                                                {item.text}
                                            </label>
                                        </div>
                                    )
                                })
                            }
                            {errors.orri && (
                                <p className="error-message">{errors.orri.message}</p>
                            )}
                        </div>
                        <div className="form-group mt-3">
                            <label className="form-label" htmlFor="abstract">Your expertise in brief (300 words max)</label>
                            <textarea rows={14} {...register("brief")} className={`form-control mt-1 ${errors.brief ? 'is-invalid' : ''}`} id="brief"></textarea>
                            {errors.achieved && (
                                <p className="error-message">{errors.brief.message}</p>
                            )}
                        </div>
                        <div className="form-group mt-3">
                            <label className="form-label">Upload your CV</label>
                            <input type="file" accept="application/pdf" className={`form-control mt-1`} onChange={handleFileChange} id="cv" />
                            {errors.cv && (
                                <p className="error-message">{errors.cv.message}</p>
                            )}
                        </div>
                        <div className="form-group mt-3">
                            <input type="checkbox" id="accept" {...register("termsOfService")} />I have read and agree to the <a href="/Privacy policy.pdf" download >privacy policy</a>
                            {errors.termsOfService && (
                                <p className="error-message">{errors.termsOfService.message}</p>
                            )}
                        </div>
                        <Button type="submit" variant="primary" className="btn btn-primary mt-5 register" form="createForm">
                            Submit
                        </Button>
                    </Form>
                </div>
            </div>
            <Newsletter />
            <ContactUs />
            <SocialAndNewsletter />
            <Footer />
        </div>
    )
}