import SocialAndNewsletter from "../../components/SocialAndNewsletter";
import ContactUs from "../../components/ContactUs";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

import './OrriMapPage.css';

export default function OrriMapPage() {
    return (
        <div className="page-container orrimap">
            <Header className="header-orrimap" />
            <div id="title" className="orri-title">
                <div className="content">
                    <div className="title-subtitle">ORRI Map</div>
                    <div className="title-title">Orri ambassadors and facilitators map</div>
                </div>
            </div>
            <div id="orri-map-explanation">
                <div id="breadcrumb">
                    <div className="content">
                        Home / ORRI Map
                    </div>
                </div>
                <div className="content">
                    <div className="orri-map-body">
                        <div className="orri-map-body-title">
                            <p>
                                REINFORCING will develop a virtual map where you will be able to find peers to
                                help you in your journey.
                            </p>
                        </div>
                        <div className="orri-map-body-subtitle">
                            <p>
                                This map will help institutions connect with local
                                experts, matching their need for specific competences
                                and will be used to provide matchmaking services.
                            </p>
                        </div>
                        <div className="view-orri-map-btn">
                            <a href="/orri-map/map" className="view-orri-map">View Orri map</a>
                        </div>
                    </div>
                </div>
            </div>
            <ContactUs />
            <SocialAndNewsletter />
            <Footer />
        </div>
    );
}