import PostPageContent from "../components/PostPageContent";
import { Link, useParams } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import Footer from "../components/Footer";
import Header from "../components/Header";
import React from "react";

import "./PostPage.css";

const GET_POST_BY_SLUG = gql`
  query MyQuery($slug: ID!) {
    post(id: $slug, idType: SLUG) {
      title
      date
      content
      author {
        node {
          name
        }
      }
      categories {
        nodes {
          slug
          name
        }
      }
    }
  }
`;

export default function PostPage(props) {
  
    const { slug } = useParams();
    const { loading, error, data } = useQuery(GET_POST_BY_SLUG, {
        variables: {
            slug: slug
        }
    });
    const postFound = Boolean(data?.post);

    return (
        <div className="page-container">
            <Header className="header-news" />
            <div id="news-banner">
                <div className="content">
                    <h1 id="news-title">
                        News &amp; Events
                    </h1>
                </div>
            </div>
            <div id="content">
                <div className="content news">
                    {loading ? (
                        <p className="mt-4">Loading...</p>
                    ) : error ? (
                        <p className="mt-4">Error: {error.message}</p>
                    ) : !postFound ? (
                        <p className="mt-4">Post could not be found.</p>
                    ) : (
                        <PostPageContent post={data.post} />
                    )}
                    <Link id="post-page-link" to="/news">← News</Link>
                </div>
            </div>
            <Footer />
        </div>
    );
}