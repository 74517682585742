import "./ContactUs.css";

export default function ContactUsCallForEvaluators() {
    return (
        <div id="contact-us">
            <div className="sub-footer-question-logo"></div>
            <div className="title">
                Do you have a question?
            </div>
            <div className="body">
                Do you have a question on the open call for evaluators? Contact us!
            </div>
            <div className="email">
                <a href="mailto:evaluators@reinforcing.eu">
                    evaluators@reinforcing.eu
                </a>
            </div>
        </div>
    );
}